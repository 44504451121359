import { RouteRecordRaw } from "vue-router";
import { useAuthStore } from "../../../composables/useAuthStore";
import { isSupplier, isSupport } from "../../../router/guards";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/plate",
    name: "plate",
    component: () => import(/* webpackChunkName: "plate" */ "../views/Plate.vue"),
    beforeEnter: [isSupplier, isSupport],
    props: () => {
      const { user } = useAuthStore();

      return {
        organization_id: user.value?.organization_id,
        isBusiness: false,
      };
    },
  },
  {
    path: "/plate/create",
    name: "create-plate",
    component: () => import(/* webpackChunkName: "create-plate" */ "../views/PlateForm.vue"),
    beforeEnter: [isSupplier, isSupport],
  },
  {
    path: "/plate/:id",
    name: "edit-plate",
    component: () => import(/* webpackChunkName: "edit-plate" */ "../views/PlateForm.vue"),
    beforeEnter: [isSupplier, isSupport],
    props: (route) => ({ plate_id: Array.isArray(route.params.id) ? parseInt(route.params.id[0]) : parseInt(route.params.id) }),
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import(/* webpackChunkName: "menu" */ "../views/Menu.vue"),
    beforeEnter: [isSupplier, isSupport],
    props: () => {
      const { user } = useAuthStore();

      return {
        organization_id: user.value?.organization_id,
      };
    },
  },
  {
    path: "/menu/create",
    name: "create-menu",
    component: () => import(/* webpackChunkName: "create-menu" */ "../views/MenuForm.vue"),
    beforeEnter: [isSupplier, isSupport],
  },
  {
    path: "/menu/:id",
    name: "edit-menu",
    component: () => import(/* webpackChunkName: "edit-menu" */ "../views/MenuForm.vue"),
    beforeEnter: [isSupplier, isSupport],
    props: (route) => ({ menu_id: Array.isArray(route.params.id) ? parseInt(route.params.id[0]) : parseInt(route.params.id) }),
  },
];

export default routes;
