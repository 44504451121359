import { SidebarMenuItem } from "../models/SidebarMenuItem";
import { Roles } from "./Roles";

const items: SidebarMenuItem[] = [
  {
    label: "Home",
    items: [{ label: "Dashboard", icon: "pi pi-fw pi-home", to: { name: "dashboard" } }],
  },
  {
    label: "Tools",
    items: [
      { label: "Plate", icon: "fa-solid fa-burger", to: { name: "plate" }, roles: [[Roles.support]] },
      { label: "Menu", icon: "fa-solid fa-utensils", to: { name: "menu" }, roles: [[Roles.support]] },
    ],
  },
  {
    label: "Reports",
    items: [
      { label: "Orders", icon: "fa-solid fa-cart-shopping", to: { name: "restaurants.reports.orders" }, roles: [[Roles.support]] },
      { label: "Deliveries", icon: "fa-solid fa-motorcycle", to: { name: "restaurants.reports.deliveries" }, roles: [[Roles.support]] },
    ],
  },
  {
    label: "Organization",
    items: [
      { label: "Profile", icon: "fa-solid fa-landmark", to: { name: "organizations.profile" }, admin: true },
      { label: "Users", icon: "fa-solid fa-users-gear", to: { name: "organizations.users" }, admin: true },
    ],
  },
];

export default items;
