import { Component } from "vue";
import { Severities } from "../enums/Severities";
import { ModalOpenPosition } from "../components/Modal";
import { useModal } from "../components/Modal/composables/useModal";

export const useLayoutModal = () => {
  const { component, props, events, severity, header, openPosition, maximizable, visible, width, closable, onclose } = useModal();

  return {
    open: (
      body: Component,
      title: string,
      bodyProps?: { [key: string]: unknown },
      bodyEvents?: { [key: string]: unknown },
      config?: { severity?: Severities; position?: ModalOpenPosition; maximizable?: boolean; width?: string; closable?: boolean },
    ) => {
      component.value = body;
      header.value = title;
      props.value = bodyProps;
      events.value = bodyEvents;
      severity.value = config?.severity ?? Severities.Primary;
      openPosition.value = config?.position ?? ModalOpenPosition.Center;
      maximizable.value = config?.maximizable ?? false;
      width.value = config?.width ?? "50vw";
      visible.value = true;
      closable.value = config?.closable ?? true;
    },
    close: () => {
      visible.value = false;

      onclose();
    },
  };
};
