import { MutationTree } from "vuex";
import { AuthState } from "./state";
import { User } from "../../models/User";
import { useSidebarMenuItems } from "../../composables/useSidebarMenuItems";
import SidebarMenuBusiness from "../../configs/SidebarMenuBusiness";
import SidebarMenuItemsSupplier from "../../configs/SidebarMenuItemsSupplier";
import { RouteLocationRaw } from "vue-router";

const { setSidebarMenuItems, clearSidebarMenuItems } = useSidebarMenuItems();

const mutation: MutationTree<AuthState> = {
  setUser(state, user: User) {
    state.user = user;

    setSidebarMenuItems(user.organization?.type === "B" ? SidebarMenuBusiness : SidebarMenuItemsSupplier, state.user);
  },

  setLasRoute(state, route: RouteLocationRaw) {
    state.lastRoute = route;
  },

  unsetUser(state) {
    state.user = undefined;

    clearSidebarMenuItems();
  },

  startLoading(state) {
    state.loading = true;
  },

  endLoading(state) {
    state.loading = false;
  },
};

export default mutation;
