import { Ref, ref, computed } from "vue";
import env from "@/env.json";

const layoutState = ref({
  staticMenuDesktopInactive: true,
  overlayMenuActive: false,
  profileSidebarVisible: false,
  configSidebarVisible: false,
  staticMenuMobileActive: false,
  menuHoverActive: false,
});

const layoutConfig = ref<{
  ripple: boolean;
  darkTheme: boolean;
  menuMode: string; // "static" | "overlay";
  scale: number;
  activeMenuItem?: string;
}>({
  ripple: true,
  darkTheme: env.DARK_THEME,
  menuMode: env.MENU_MODE,
  scale: env.SCALE,
  activeMenuItem: undefined,
});

const profileMenu = ref();

export function useLayout() {
  const setActiveMenuItem = (item: Ref<string | undefined> | string | undefined) => {
    layoutConfig.value.activeMenuItem = typeof item === "string" ? item : item?.value;
  };

  const onMenuToggle = () => {
    if (layoutConfig.value.menuMode === "overlay") {
      layoutState.value.overlayMenuActive = !layoutState.value.overlayMenuActive;
    }

    if (window.innerWidth > 991) {
      layoutState.value.staticMenuDesktopInactive = !layoutState.value.staticMenuDesktopInactive;
    } else {
      layoutState.value.staticMenuMobileActive = !layoutState.value.staticMenuMobileActive;
    }
  };

  const ProfileMenuToggle = (event: unknown) => {
    profileMenu.value?.toggle(event);
  };

  const isSidebarActive = computed(() => layoutState.value.overlayMenuActive || layoutState.value.staticMenuMobileActive);

  const isDarkTheme = computed(() => layoutConfig.value.darkTheme);

  const logo = computed(() => `/assets/${layoutConfig.value.darkTheme ? "logo-dark" : "logo-white"}.png`);

  const bgImg = computed(() => "/assets/bg.jpg");

  const title = env.TITLE;

  const subtitle = env.SUBTITLE;

  return { layoutState, layoutConfig, onMenuToggle, setActiveMenuItem, isSidebarActive, isDarkTheme, logo, bgImg, title, subtitle, profileMenu, ProfileMenuToggle };
}
