/**
 * Enum of input types
 *
 * @author: Josué Cruz <jicruz@voiceteam.com>
 * @version: 1.0
 * @since: 2023-06-05
 * @copyright: Voiceteam Call
 */
export enum InputTypes {
  text = 0,
  description,
  int,
  float,
  boolean,
  date,
  datetime,
  select,
  radio,
  checkbox,
  tristate,
  currency,
  file,
  files,
  color,
  rating,
  switch,
  time,
  dropdown,
  password,
}
