import { FilterItemTypes } from "../enums/FilterItemTypes";
import { FilterMethods } from "../enums/FilterMethods";

const filterItemTypesToFilterMethods: { [key: number]: FilterMethods[] } = {};

filterItemTypesToFilterMethods[FilterItemTypes.text] = [FilterMethods.equal, FilterMethods.startWith, FilterMethods.endWith, FilterMethods.contains];
filterItemTypesToFilterMethods[FilterItemTypes.int] = [FilterMethods.equal, FilterMethods.greaterThan, FilterMethods.lowerThan, FilterMethods.greaterOrEqualThan, FilterMethods.lowerOrEqualThan, FilterMethods.between];
filterItemTypesToFilterMethods[FilterItemTypes.float] = [FilterMethods.equal, FilterMethods.greaterThan, FilterMethods.lowerThan, FilterMethods.greaterOrEqualThan, FilterMethods.lowerOrEqualThan, FilterMethods.between];
filterItemTypesToFilterMethods[FilterItemTypes.boolean] = [FilterMethods.equal];
filterItemTypesToFilterMethods[FilterItemTypes.date] = [FilterMethods.equal, FilterMethods.between, FilterMethods.greaterOrEqualThan, FilterMethods.lowerOrEqualThan];
filterItemTypesToFilterMethods[FilterItemTypes.datetime] = [FilterMethods.equal, FilterMethods.between, FilterMethods.greaterOrEqualThan, FilterMethods.lowerOrEqualThan];
filterItemTypesToFilterMethods[FilterItemTypes.select] = [FilterMethods.equal, FilterMethods.isOneOf, FilterMethods.isNotOneOf, FilterMethods.isNotEqual];
filterItemTypesToFilterMethods[FilterItemTypes.tristate] = [FilterMethods.equal];

export default filterItemTypesToFilterMethods;
