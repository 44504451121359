import { SidebarMenuItem } from "../models/SidebarMenuItem";
import { Roles } from "./Roles";

const items: SidebarMenuItem[] = [
  {
    label: "Home",
    items: [{ label: "Dashboard", icon: "pi pi-fw pi-home", to: { name: "dashboard" } }],
  },
  {
    label: "Reports",
    items: [
      { label: "Orders", icon: "fa-solid fa-cart-shopping", to: { name: "businesses.reports.orders" }, roles: [[Roles.support]] },
      { label: "Details", icon: "fa-solid fa-file-invoice-dollar", to: { name: "businesses.reports.details" }, roles: [[Roles.finance]] },
    ],
  },
  {
    label: "Organization",
    items: [
      { label: "Cost Centers", icon: "fa-solid fa-people-roof", to: { name: "organizations.costCenters" }, admin: true },
      { label: "Locations", icon: "fa-solid fa-location", to: { name: "organizations.locations" }, admin: true },
      { label: "Profile", icon: "fa-solid fa-landmark", to: { name: "organizations.profile" }, admin: true },
      { label: "Suppliers", icon: "fa-solid fa-boxes-stacked", to: { name: "organizations.suppliers" }, admin: true },
      { label: "Users", icon: "fa-solid fa-users-gear", to: { name: "organizations.users" }, admin: true },
    ],
  },
];

export default items;
