import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "menu-separator"
}
const _hoisted_2 = {
  key: 0,
  class: "layout-menuitem-root-text"
}
const _hoisted_3 = ["href", "target"]
const _hoisted_4 = { class: "layout-menuitem-text" }
const _hoisted_5 = {
  key: 0,
  class: "pi pi-fw pi-angle-down layout-submenu-toggler"
}
const _hoisted_6 = { class: "layout-menuitem-text" }
const _hoisted_7 = {
  key: 0,
  class: "pi pi-fw pi-angle-down layout-submenu-toggler"
}
const _hoisted_8 = { class: "layout-submenu" }

import { ref, onBeforeMount, watch } from "vue";
import { useRoute } from "vue-router";
import { useLayout } from "./composables/Layout";
import { SidebarMenuItem } from "../../models/SidebarMenuItem";


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuItem',
  props: {
    item: {},
    index: { default: 0 },
    root: { type: Boolean, default: true },
    parentItemKey: {}
  },
  setup(__props: any) {

const route = useRoute();

const { layoutConfig, layoutState, setActiveMenuItem, onMenuToggle } = useLayout();

const props = __props;

const isActiveMenu = ref(false);
const itemKey = ref<string | undefined>();

onBeforeMount(() => {
  itemKey.value = props.parentItemKey ? props.parentItemKey + "-" + props.index : String(props.index);

  const activeItem = layoutConfig.value.activeMenuItem;

  isActiveMenu.value = activeItem === itemKey.value || (activeItem?.startsWith(itemKey.value + "-") ?? false);
});

watch(
  () => layoutConfig.value.activeMenuItem,
  (newVal) => {
    isActiveMenu.value = newVal === itemKey.value || (newVal?.startsWith(itemKey.value + "-") ?? false);
  },
);

const itemClick = (event: MouseEvent, item: SidebarMenuItem) => {
  if (item.disabled) {
    event.preventDefault();
    return;
  }

  const { overlayMenuActive, staticMenuMobileActive } = layoutState.value;

  if ((item.to || item.url) && (staticMenuMobileActive || overlayMenuActive)) {
    onMenuToggle();
  }

  if (item.command) {
    item.command(event, item);
  }

  const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey : itemKey) : itemKey.value;

  setActiveMenuItem(foundItemKey);
};

const checkActiveRoute = (item: SidebarMenuItem) => {
  return item.to ? (typeof item.to === "string" ? route.path === item.to : route.name === item.to.name) : false;
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_menu_item = _resolveComponent("menu-item", true)!

  return (_ctx.item.separator && _ctx.item.visible !== false)
    ? (_openBlock(), _createElementBlock("li", _hoisted_1))
    : (_openBlock(), _createElementBlock("li", {
        key: 1,
        class: _normalizeClass({ 'layout-root-menuitem': _ctx.root, 'active-menuitem': isActiveMenu.value })
      }, [
        (_ctx.root && _ctx.item.visible !== false)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.item.label), 1))
          : _createCommentVNode("", true),
        ((!_ctx.item.to || _ctx.item.items) && _ctx.item.visible !== false)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              href: _ctx.item.url,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (itemClick($event, _ctx.item))),
              class: _normalizeClass(_ctx.item.class),
              target: _ctx.item.target,
              tabindex: "0"
            }, [
              _createElementVNode("i", {
                class: _normalizeClass([_ctx.item.icon, "layout-menuitem-icon"])
              }, null, 2),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.item.label), 1),
              (_ctx.item.items)
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : _createCommentVNode("", true)
            ], 10, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.item.to && !_ctx.item.items && _ctx.item.visible !== false)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 2,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (itemClick($event, _ctx.item))),
              class: _normalizeClass([_ctx.item.class, { 'active-route': checkActiveRoute(_ctx.item) }]),
              tabindex: "0",
              to: _ctx.item.to
            }, {
              default: _withCtx(() => [
                _createElementVNode("i", {
                  class: _normalizeClass([_ctx.item.icon, "layout-menuitem-icon"])
                }, null, 2),
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.item.label), 1),
                (_ctx.item.items)
                  ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["class", "to"]))
          : _createCommentVNode("", true),
        (_ctx.item.items && _ctx.item.visible !== false)
          ? (_openBlock(), _createBlock(_Transition, {
              key: 3,
              name: "layout-submenu"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("ul", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.items, (child, i) => {
                    return (_openBlock(), _createBlock(_component_menu_item, {
                      key: i,
                      index: i,
                      item: child,
                      parentItemKey: itemKey.value,
                      root: false
                    }, null, 8, ["index", "item", "parentItemKey"]))
                  }), 128))
                ], 512), [
                  [_vShow, _ctx.root ? true : isActiveMenu.value]
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2))
}
}

})