import { ActionTree } from "vuex";
import { CartState } from "./state";
import { StateInterface } from "../index";
import { NavigationFailure, RouteLocationRaw, Router } from "vue-router";
import { Http } from "../../services/Http";
import { Order } from "../../models/Order";

export type PushRouter = Partial<Router> & { push: (to: RouteLocationRaw) => Promise<void | NavigationFailure | undefined> };

const http = new Http();

const actions: ActionTree<CartState, StateInterface> = {
  async store({ commit, state }) {
    if (state.items.length === 0) return;
    // Todo: notify error

    commit("startLoading");

    const supplier_id = state.items[0].restaurant_id;
    const items = state.items.map((item) => ({
      quantity: item.quantity,
      plate_id: item.plate_id,
      code: item.code,
      menu_id: item.menu_id,
      selected: item.selectedOptionValues.filter((option) => (option ? true : false)).map((option) => (Array.isArray(option) ? option.map((o) => o.name) : [option?.name])),
    }));

    const { data } = await http.post<Order>("orders", { supplier_id, items });

    if (data.success) {
      commit("clear");
    }

    commit("endLoading");

    return data;
  },
};

export default actions;
