import { createStore } from "vuex";

import AuthModule from "./auth";
import CartModule from "./cart";
import { AuthState } from "./auth/state";
import { CartState } from "./cart/state";

export interface StateInterface {
  auth: AuthState;
  cart: CartState;
}

export default createStore({
  modules: {
    auth: AuthModule,
    cart: CartModule,
  },
});
