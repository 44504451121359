<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from "vue";
import { InputTypes } from "../enums/InputTypes";
import { toUnix } from "../../../utils/helpers";
import FileInput from "../../FileInput/components/FileInput.vue";
import { useDynamicInput } from "../composables/useDynamicInput";
import SelectInput from "../../SelectInput/components/SelectInput.vue";

declare type DynamicInputTypeProps = {
  /**
   * Input type to render
   */
  type: InputTypes;

  /**
   * when present, makes the element not mutable, meaning the user can not edit the control
   */
  readonly?: boolean;

  /**
   * a boolean which indicates whether the control is disabled.
   */
  disabled?: boolean;

  /**
   * defines the text displayed in a form control when the control has no value.
   */
  placeholder?: string;

  /**
   * a space-separated list of the case-sensitive classes of the element.
   */
  class?: string;

  /**
   * name of element
   */
  name?: string;

  /**
   * for v-model support
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modelValue?: any | any[];

  /**
   * text input prop configuration
   */
  text?: {
    /**
     * text input type
     */
    type: "text" | "email" | "tel" | "url";
  };

  /**
   * int input prop configuration
   */
  int?: {
    /**
     * min value
     */
    min?: number;

    /**
     * max value
     */
    max?: number;

    /**
     * if include the buttons
     */
    buttons?: boolean;

    /**
     * input number step
     */
    step?: number;
  };
  float?: {
    /**
     * min digits before dot
     */
    minFractionDigits?: number;

    /**
     * max digits before dot
     */
    maxFractionDigits?: number;

    /**
     * min value
     */
    min?: number;

    /**
     * max value
     */
    max?: number;

    /**
     * if include the buttons
     */
    buttons?: boolean;

    /**
     * input number step
     */
    step?: number;
  };
  date?: {
    /**
     * min value
     */
    min?: Date;

    /**
     * max value
     */
    max?: Date;

    /**
     * date format https://primevue.org/calendar/#format
     */
    format?: string;

    /**
     * if allow range selection
     */
    range?: boolean;
  };
  datetime?: {
    /**
     * min value
     */
    min?: Date;

    /**
     * max value
     */
    max?: Date;

    /**
     * date format https://primevue.org/calendar/#format
     */
    format?: string;

    /**
     * if allow range selection
     */
    range?: boolean;
  };
  select?: {
    /**
     * a boolean which indicates if the control has search area.
     */
    filter?: boolean;

    /**
     * Maximum number of selectable items. <= 0 to unlimited
     */
    limit?: number;

    /**
     * Whether to show the header checkbox to toggle the selection of all items at once.
     */
    maxSelectedLabels?: number;

    /**
     * Property name or getter function to use as the label of an option.
     */
    optionLabel?: string | ((data: unknown) => string);

    /**
     * An array of items to display as the available options.
     */
    options?: { [key: string]: unknown }[];

    /**
     * Property name or getter function to use as the value of an option, defaults to the option itself when not defined.
     */
    optionValue?: string | ((data: unknown) => unknown);

    /**
     * load options items from get url
     */
    url?: string;
  };
  radio?: {
    /**
     * An array of items to display as the available options.
     */
    options?: { [key: string]: any }[];

    /**
     * Property name to use as the value of an option, defaults to the option itself when not defined.
     */
    optionValue?: string;

    /**
     * Property name to use as the label of an option.
     */
    optionLabel?: string;
  };
  checkbox?: {
    /**
     * An array of items to display as the available options.
     */
    options?: { [key: string]: any }[];

    /**
     * Property name to use as the value of an option, defaults to the option itself when not defined.
     */
    optionValue?: string;

    /**
     * Property name to use as the label of an option.
     */
    optionLabel?: string;
  };
  currency?: {
    /**
     * min value
     */
    min?: number;

    /**
     * max value
     */
    max?: number;

    /**
     * The currency to use in currency formatting. Possible values are the [ISO 4217 currency codes](https://www.six-group.com/en/products-services/financial-information/data-standards.html#scrollTo=maintenance-agency), such as 'USD' for the US dollar, 'EUR' for the euro, or 'CNY' for the Chinese RMB.
     */
    currency?: string;

    /**
     * Locale to be used in formatting.
     */
    locale?: string;
  };
  file?: {
    /**
     * takes as its value a comma-separated list of one or more file types, or unique file type specifiers, describing which file types to allow. Like accept in &lt;input type=&quot;file&quot; /&gt;
     */
    accept?: string;
  };
  files?: {
    /**
     * takes as its value a comma-separated list of one or more file types, or unique file type specifiers, describing which file types to allow. Like accept in &lt;input type=&quot;file&quot; /&gt;
     */
    accept?: string;
  };
  color?: {
    /**
     * color value format
     */
    format?: "hex" | "rgb" | "hsb";
  };
  rating?: {
    /**
     * max start to evaluate
     */
    stars?: number;
  };
  time?: {
    /**
     * min value
     */
    min?: Date;

    /**
     * max value
     */
    max?: Date;

    /**
     * if time format is in 12 or 24 hours
     */
    format: "12" | "24";
  };
  dropdown?: {
    /**
     * An array of items to display as the available options.
     */
    options?: { [key: string]: unknown }[];

    /**
     * Property name to use as the value of an option, defaults to the option itself when not defined.
     */
    optionValue?: string;

    /**
     * Property name to use as the label of an option.
     */
    optionLabel?: string;
  };
};

const props = withDefaults(defineProps<DynamicInputTypeProps>(), {
  readonly: false,
  disabled: false,
  name: toUnix().toString(),
});

const emit = defineEmits(["update:modelValue"]);

const { value, config } = useDynamicInput(props, emit);
</script>

<template>
  <InputText :type="config.text" v-model="value" :placeholder="placeholder" :class="props.class" :disabled="disabled" :readonly="readonly" :name="name" v-if="type === InputTypes.text"></InputText>

  <Textarea v-model="value" :placeholder="placeholder" :class="props.class" :disabled="disabled" :readonly="readonly" :name="name" v-else-if="type === InputTypes.description" :autoResize="false"></Textarea>

  <InputNumber
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :disabled="disabled"
    :readonly="readonly"
    :name="name"
    v-else-if="type === InputTypes.int"
    :min="config.min"
    :max="config.max"
    :show-buttons="config.buttons"
    :step="config.step"
  ></InputNumber>

  <InputNumber
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :disabled="disabled"
    :readonly="readonly"
    :name="name"
    v-else-if="type === InputTypes.float"
    :minFractionDigits="config.minFractionDigits"
    :maxFractionDigits="config.maxFractionDigits"
    :min="config.min"
    :max="config.max"
    :show-buttons="config.buttons"
    :step="config.step"
  ></InputNumber>

  <Checkbox v-model="value" :class="props.class" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :name="name" :binary="true" v-else-if="type === InputTypes.boolean"></Checkbox>

  <Calendar
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :disabled="disabled"
    :readonly="readonly"
    :name="name"
    v-else-if="type === InputTypes.date"
    :dateFormat="config.format"
    :selectionMode="config.range"
    :minDate="config.min"
    :maxDate="config.max"
    appendTo="body"
  ></Calendar>

  <Calendar
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :disabled="disabled"
    :readonly="readonly"
    :showTime="true"
    :name="name"
    v-else-if="type === InputTypes.datetime"
    :dateFormat="config.format"
    :selectionMode="config.range"
    :minDate="config.min"
    appendTo="body"
  ></Calendar>

  <SelectInput
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :disabled="disabled"
    :readonly="readonly"
    :name="name"
    v-else-if="type === InputTypes.select"
    :options="config.options"
    :optionLabel="config.optionLabel"
    :optionValue="config.optionValue"
    :limit="config.limit"
    :maxSelectedLabels="config.maxSelectedLabels"
    :filter="config.filter"
    :url="config.url"
  ></SelectInput>

  <div v-else-if="type === InputTypes.radio">
    <div class="p-field-radiobutton" v-for="option of config.options" :key="option[config.optionValue]">
      <RadioButton v-model="value" :class="props.class" :disabled="disabled || readonly" :readonly="readonly" :placeholder="placeholder" :name="name" :value="option[config.optionValue]"></RadioButton>
      <label class="ml-2 radio-label">{{ option[config.optionLabel] }}</label>
    </div>
  </div>

  <div v-else-if="type === InputTypes.checkbox">
    <div class="p-field-checkbox" v-for="option of config.options" :key="option[config.optionValue]">
      <Checkbox v-model="value" :class="props.class" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :name="name" :value="option[config.optionValue]"></Checkbox>
      <label class="ml-2 checkbox-label">{{ option[config.optionLabel] }}</label>
    </div>
  </div>

  <TriStateCheckbox v-model="value" :class="props.class" :disabled="disabled" :readonly="readonly" :placeholder="placeholder" :name="name" v-else-if="type === InputTypes.tristate"></TriStateCheckbox>

  <InputNumber
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :disabled="disabled"
    :readonly="readonly"
    :name="name"
    v-else-if="type === InputTypes.currency"
    :minFractionDigits="2"
    :maxFractionDigits="2"
    :min="config.min"
    :max="config.max"
    mode="currency"
    :currency="config.currency"
    :locale="config.locale"
  ></InputNumber>

  <FileInput
    v-model="value"
    :class="props.class"
    :readonly="readonly"
    :disabled="disabled"
    :multiple="type === InputTypes.files"
    :accept="config.accept"
    :placeholder="placeholder"
    :name="name"
    v-else-if="type === InputTypes.files || type === InputTypes.file"
  ></FileInput>

  <ColorPicker v-model="value" :class="props.class" :readonly="readonly" :disabled="disabled || readonly" :placeholder="placeholder" :name="name" v-else-if="type === InputTypes.color" :format="config.format"></ColorPicker>

  <Rating v-model="value" :class="props.class" :readonly="readonly" :disabled="disabled" :placeholder="placeholder" :name="name" v-else-if="type === InputTypes.rating" :stars="config.stars" :cancel="false"></Rating>

  <InputSwitch v-model="value" :class="props.class" :readonly="readonly" :disabled="disabled || readonly" :placeholder="placeholder" :name="name" v-else-if="type === InputTypes.switch"></InputSwitch>

  <Calendar
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :disabled="disabled"
    :readonly="readonly"
    :showTime="true"
    :name="name"
    timeOnly
    v-else-if="type === InputTypes.time"
    :hourFormat="config.format"
    :minDate="config.min"
    :maxDate="config.max"
    appendTo="body"
  ></Calendar>

  <Dropdown
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :readonly="readonly"
    :disabled="disabled || readonly"
    :name="name"
    v-else-if="type === InputTypes.dropdown"
    :options="config.options"
    :optionLabel="config.optionLabel"
    :optionValue="config.optionValue"
  ></Dropdown>

  <Password v-model="value" :placeholder="placeholder" :class="props.class" :inputClass="props.class" :disabled="disabled || readonly" :name="name" v-else-if="type === InputTypes.password" :feedback="false" toggleMask></Password>

  <div class="w-full flex" v-else>
    <span class="m-auto text-red-500">The type {{ type }} isn't a valid Input Type</span>
  </div>
</template>

<style lang="scss" scoped></style>
