import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 8 }
const _hoisted_2 = { class: "ml-2 radio-label" }
const _hoisted_3 = { key: 9 }
const _hoisted_4 = { class: "ml-2 checkbox-label" }
const _hoisted_5 = {
  key: 19,
  class: "w-full flex"
}
const _hoisted_6 = { class: "m-auto text-red-500" }


import { InputTypes } from "../enums/InputTypes";
import { toUnix } from "../../../utils/helpers";
import FileInput from "../../FileInput/components/FileInput.vue";
import { useDynamicInput } from "../composables/useDynamicInput";
import SelectInput from "../../SelectInput/components/SelectInput.vue";

declare type DynamicInputTypeProps = {
  /**
   * Input type to render
   */
  type: InputTypes;

  /**
   * when present, makes the element not mutable, meaning the user can not edit the control
   */
  readonly?: boolean;

  /**
   * a boolean which indicates whether the control is disabled.
   */
  disabled?: boolean;

  /**
   * defines the text displayed in a form control when the control has no value.
   */
  placeholder?: string;

  /**
   * a space-separated list of the case-sensitive classes of the element.
   */
  class?: string;

  /**
   * name of element
   */
  name?: string;

  /**
   * for v-model support
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modelValue?: any | any[];

  /**
   * text input prop configuration
   */
  text?: {
    /**
     * text input type
     */
    type: "text" | "email" | "tel" | "url";
  };

  /**
   * int input prop configuration
   */
  int?: {
    /**
     * min value
     */
    min?: number;

    /**
     * max value
     */
    max?: number;

    /**
     * if include the buttons
     */
    buttons?: boolean;

    /**
     * input number step
     */
    step?: number;
  };
  float?: {
    /**
     * min digits before dot
     */
    minFractionDigits?: number;

    /**
     * max digits before dot
     */
    maxFractionDigits?: number;

    /**
     * min value
     */
    min?: number;

    /**
     * max value
     */
    max?: number;

    /**
     * if include the buttons
     */
    buttons?: boolean;

    /**
     * input number step
     */
    step?: number;
  };
  date?: {
    /**
     * min value
     */
    min?: Date;

    /**
     * max value
     */
    max?: Date;

    /**
     * date format https://primevue.org/calendar/#format
     */
    format?: string;

    /**
     * if allow range selection
     */
    range?: boolean;
  };
  datetime?: {
    /**
     * min value
     */
    min?: Date;

    /**
     * max value
     */
    max?: Date;

    /**
     * date format https://primevue.org/calendar/#format
     */
    format?: string;

    /**
     * if allow range selection
     */
    range?: boolean;
  };
  select?: {
    /**
     * a boolean which indicates if the control has search area.
     */
    filter?: boolean;

    /**
     * Maximum number of selectable items. <= 0 to unlimited
     */
    limit?: number;

    /**
     * Whether to show the header checkbox to toggle the selection of all items at once.
     */
    maxSelectedLabels?: number;

    /**
     * Property name or getter function to use as the label of an option.
     */
    optionLabel?: string | ((data: unknown) => string);

    /**
     * An array of items to display as the available options.
     */
    options?: { [key: string]: unknown }[];

    /**
     * Property name or getter function to use as the value of an option, defaults to the option itself when not defined.
     */
    optionValue?: string | ((data: unknown) => unknown);

    /**
     * load options items from get url
     */
    url?: string;
  };
  radio?: {
    /**
     * An array of items to display as the available options.
     */
    options?: { [key: string]: any }[];

    /**
     * Property name to use as the value of an option, defaults to the option itself when not defined.
     */
    optionValue?: string;

    /**
     * Property name to use as the label of an option.
     */
    optionLabel?: string;
  };
  checkbox?: {
    /**
     * An array of items to display as the available options.
     */
    options?: { [key: string]: any }[];

    /**
     * Property name to use as the value of an option, defaults to the option itself when not defined.
     */
    optionValue?: string;

    /**
     * Property name to use as the label of an option.
     */
    optionLabel?: string;
  };
  currency?: {
    /**
     * min value
     */
    min?: number;

    /**
     * max value
     */
    max?: number;

    /**
     * The currency to use in currency formatting. Possible values are the [ISO 4217 currency codes](https://www.six-group.com/en/products-services/financial-information/data-standards.html#scrollTo=maintenance-agency), such as 'USD' for the US dollar, 'EUR' for the euro, or 'CNY' for the Chinese RMB.
     */
    currency?: string;

    /**
     * Locale to be used in formatting.
     */
    locale?: string;
  };
  file?: {
    /**
     * takes as its value a comma-separated list of one or more file types, or unique file type specifiers, describing which file types to allow. Like accept in &lt;input type=&quot;file&quot; /&gt;
     */
    accept?: string;
  };
  files?: {
    /**
     * takes as its value a comma-separated list of one or more file types, or unique file type specifiers, describing which file types to allow. Like accept in &lt;input type=&quot;file&quot; /&gt;
     */
    accept?: string;
  };
  color?: {
    /**
     * color value format
     */
    format?: "hex" | "rgb" | "hsb";
  };
  rating?: {
    /**
     * max start to evaluate
     */
    stars?: number;
  };
  time?: {
    /**
     * min value
     */
    min?: Date;

    /**
     * max value
     */
    max?: Date;

    /**
     * if time format is in 12 or 24 hours
     */
    format: "12" | "24";
  };
  dropdown?: {
    /**
     * An array of items to display as the available options.
     */
    options?: { [key: string]: unknown }[];

    /**
     * Property name to use as the value of an option, defaults to the option itself when not defined.
     */
    optionValue?: string;

    /**
     * Property name to use as the label of an option.
     */
    optionLabel?: string;
  };
};


export default /*@__PURE__*/_defineComponent({
  __name: 'DynamicInput',
  props: {
    type: {},
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    placeholder: {},
    class: {},
    name: { default: toUnix().toString() },
    modelValue: {},
    text: {},
    int: {},
    float: {},
    date: {},
    datetime: {},
    select: {},
    radio: {},
    checkbox: {},
    currency: {},
    file: {},
    files: {},
    color: {},
    rating: {},
    time: {},
    dropdown: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { value, config } = useDynamicInput(props, emit);

return (_ctx: any,_cache: any) => {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")!
  const _component_ColorPicker = _resolveComponent("ColorPicker")!
  const _component_Rating = _resolveComponent("Rating")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Password = _resolveComponent("Password")!

  return (_ctx.type === _unref(InputTypes).text)
    ? (_openBlock(), _createBlock(_component_InputText, {
        key: 0,
        type: _unref(config).text,
        modelValue: _unref(value),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
        placeholder: _ctx.placeholder,
        class: _normalizeClass(props.class),
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        name: _ctx.name
      }, null, 8, ["type", "modelValue", "placeholder", "class", "disabled", "readonly", "name"]))
    : (_ctx.type === _unref(InputTypes).description)
      ? (_openBlock(), _createBlock(_component_Textarea, {
          key: 1,
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
          placeholder: _ctx.placeholder,
          class: _normalizeClass(props.class),
          disabled: _ctx.disabled,
          readonly: _ctx.readonly,
          name: _ctx.name,
          autoResize: false
        }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name"]))
      : (_ctx.type === _unref(InputTypes).int)
        ? (_openBlock(), _createBlock(_component_InputNumber, {
            key: 2,
            modelValue: _unref(value),
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
            placeholder: _ctx.placeholder,
            class: _normalizeClass(props.class),
            disabled: _ctx.disabled,
            readonly: _ctx.readonly,
            name: _ctx.name,
            min: _unref(config).min,
            max: _unref(config).max,
            "show-buttons": _unref(config).buttons,
            step: _unref(config).step
          }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name", "min", "max", "show-buttons", "step"]))
        : (_ctx.type === _unref(InputTypes).float)
          ? (_openBlock(), _createBlock(_component_InputNumber, {
              key: 3,
              modelValue: _unref(value),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
              placeholder: _ctx.placeholder,
              class: _normalizeClass(props.class),
              disabled: _ctx.disabled,
              readonly: _ctx.readonly,
              name: _ctx.name,
              minFractionDigits: _unref(config).minFractionDigits,
              maxFractionDigits: _unref(config).maxFractionDigits,
              min: _unref(config).min,
              max: _unref(config).max,
              "show-buttons": _unref(config).buttons,
              step: _unref(config).step
            }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name", "minFractionDigits", "maxFractionDigits", "min", "max", "show-buttons", "step"]))
          : (_ctx.type === _unref(InputTypes).boolean)
            ? (_openBlock(), _createBlock(_component_Checkbox, {
                key: 4,
                modelValue: _unref(value),
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                class: _normalizeClass(props.class),
                disabled: _ctx.disabled,
                readonly: _ctx.readonly,
                placeholder: _ctx.placeholder,
                name: _ctx.name,
                binary: true
              }, null, 8, ["modelValue", "class", "disabled", "readonly", "placeholder", "name"]))
            : (_ctx.type === _unref(InputTypes).date)
              ? (_openBlock(), _createBlock(_component_Calendar, {
                  key: 5,
                  modelValue: _unref(value),
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                  placeholder: _ctx.placeholder,
                  class: _normalizeClass(props.class),
                  disabled: _ctx.disabled,
                  readonly: _ctx.readonly,
                  name: _ctx.name,
                  dateFormat: _unref(config).format,
                  selectionMode: _unref(config).range,
                  minDate: _unref(config).min,
                  maxDate: _unref(config).max,
                  appendTo: "body"
                }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name", "dateFormat", "selectionMode", "minDate", "maxDate"]))
              : (_ctx.type === _unref(InputTypes).datetime)
                ? (_openBlock(), _createBlock(_component_Calendar, {
                    key: 6,
                    modelValue: _unref(value),
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                    placeholder: _ctx.placeholder,
                    class: _normalizeClass(props.class),
                    disabled: _ctx.disabled,
                    readonly: _ctx.readonly,
                    showTime: true,
                    name: _ctx.name,
                    dateFormat: _unref(config).format,
                    selectionMode: _unref(config).range,
                    minDate: _unref(config).min,
                    appendTo: "body"
                  }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name", "dateFormat", "selectionMode", "minDate"]))
                : (_ctx.type === _unref(InputTypes).select)
                  ? (_openBlock(), _createBlock(SelectInput, {
                      key: 7,
                      modelValue: _unref(value),
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                      placeholder: _ctx.placeholder,
                      class: _normalizeClass(props.class),
                      disabled: _ctx.disabled,
                      readonly: _ctx.readonly,
                      name: _ctx.name,
                      options: _unref(config).options,
                      optionLabel: _unref(config).optionLabel,
                      optionValue: _unref(config).optionValue,
                      limit: _unref(config).limit,
                      maxSelectedLabels: _unref(config).maxSelectedLabels,
                      filter: _unref(config).filter,
                      url: _unref(config).url
                    }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name", "options", "optionLabel", "optionValue", "limit", "maxSelectedLabels", "filter", "url"]))
                  : (_ctx.type === _unref(InputTypes).radio)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config).options, (option) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "p-field-radiobutton",
                            key: option[_unref(config).optionValue]
                          }, [
                            _createVNode(_component_RadioButton, {
                              modelValue: _unref(value),
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                              class: _normalizeClass(props.class),
                              disabled: _ctx.disabled || _ctx.readonly,
                              readonly: _ctx.readonly,
                              placeholder: _ctx.placeholder,
                              name: _ctx.name,
                              value: option[_unref(config).optionValue]
                            }, null, 8, ["modelValue", "class", "disabled", "readonly", "placeholder", "name", "value"]),
                            _createElementVNode("label", _hoisted_2, _toDisplayString(option[_unref(config).optionLabel]), 1)
                          ]))
                        }), 128))
                      ]))
                    : (_ctx.type === _unref(InputTypes).checkbox)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config).options, (option) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "p-field-checkbox",
                              key: option[_unref(config).optionValue]
                            }, [
                              _createVNode(_component_Checkbox, {
                                modelValue: _unref(value),
                                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                                class: _normalizeClass(props.class),
                                disabled: _ctx.disabled,
                                readonly: _ctx.readonly,
                                placeholder: _ctx.placeholder,
                                name: _ctx.name,
                                value: option[_unref(config).optionValue]
                              }, null, 8, ["modelValue", "class", "disabled", "readonly", "placeholder", "name", "value"]),
                              _createElementVNode("label", _hoisted_4, _toDisplayString(option[_unref(config).optionLabel]), 1)
                            ]))
                          }), 128))
                        ]))
                      : (_ctx.type === _unref(InputTypes).tristate)
                        ? (_openBlock(), _createBlock(_component_TriStateCheckbox, {
                            key: 10,
                            modelValue: _unref(value),
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                            class: _normalizeClass(props.class),
                            disabled: _ctx.disabled,
                            readonly: _ctx.readonly,
                            placeholder: _ctx.placeholder,
                            name: _ctx.name
                          }, null, 8, ["modelValue", "class", "disabled", "readonly", "placeholder", "name"]))
                        : (_ctx.type === _unref(InputTypes).currency)
                          ? (_openBlock(), _createBlock(_component_InputNumber, {
                              key: 11,
                              modelValue: _unref(value),
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                              placeholder: _ctx.placeholder,
                              class: _normalizeClass(props.class),
                              disabled: _ctx.disabled,
                              readonly: _ctx.readonly,
                              name: _ctx.name,
                              minFractionDigits: 2,
                              maxFractionDigits: 2,
                              min: _unref(config).min,
                              max: _unref(config).max,
                              mode: "currency",
                              currency: _unref(config).currency,
                              locale: _unref(config).locale
                            }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name", "min", "max", "currency", "locale"]))
                          : (_ctx.type === _unref(InputTypes).files || _ctx.type === _unref(InputTypes).file)
                            ? (_openBlock(), _createBlock(FileInput, {
                                key: 12,
                                modelValue: _unref(value),
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                                class: _normalizeClass(props.class),
                                readonly: _ctx.readonly,
                                disabled: _ctx.disabled,
                                multiple: _ctx.type === _unref(InputTypes).files,
                                accept: _unref(config).accept,
                                placeholder: _ctx.placeholder,
                                name: _ctx.name
                              }, null, 8, ["modelValue", "class", "readonly", "disabled", "multiple", "accept", "placeholder", "name"]))
                            : (_ctx.type === _unref(InputTypes).color)
                              ? (_openBlock(), _createBlock(_component_ColorPicker, {
                                  key: 13,
                                  modelValue: _unref(value),
                                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                                  class: _normalizeClass(props.class),
                                  readonly: _ctx.readonly,
                                  disabled: _ctx.disabled || _ctx.readonly,
                                  placeholder: _ctx.placeholder,
                                  name: _ctx.name,
                                  format: _unref(config).format
                                }, null, 8, ["modelValue", "class", "readonly", "disabled", "placeholder", "name", "format"]))
                              : (_ctx.type === _unref(InputTypes).rating)
                                ? (_openBlock(), _createBlock(_component_Rating, {
                                    key: 14,
                                    modelValue: _unref(value),
                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                                    class: _normalizeClass(props.class),
                                    readonly: _ctx.readonly,
                                    disabled: _ctx.disabled,
                                    placeholder: _ctx.placeholder,
                                    name: _ctx.name,
                                    stars: _unref(config).stars,
                                    cancel: false
                                  }, null, 8, ["modelValue", "class", "readonly", "disabled", "placeholder", "name", "stars"]))
                                : (_ctx.type === _unref(InputTypes).switch)
                                  ? (_openBlock(), _createBlock(_component_InputSwitch, {
                                      key: 15,
                                      modelValue: _unref(value),
                                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                                      class: _normalizeClass(props.class),
                                      readonly: _ctx.readonly,
                                      disabled: _ctx.disabled || _ctx.readonly,
                                      placeholder: _ctx.placeholder,
                                      name: _ctx.name
                                    }, null, 8, ["modelValue", "class", "readonly", "disabled", "placeholder", "name"]))
                                  : (_ctx.type === _unref(InputTypes).time)
                                    ? (_openBlock(), _createBlock(_component_Calendar, {
                                        key: 16,
                                        modelValue: _unref(value),
                                        "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                                        placeholder: _ctx.placeholder,
                                        class: _normalizeClass(props.class),
                                        disabled: _ctx.disabled,
                                        readonly: _ctx.readonly,
                                        showTime: true,
                                        name: _ctx.name,
                                        timeOnly: "",
                                        hourFormat: _unref(config).format,
                                        minDate: _unref(config).min,
                                        maxDate: _unref(config).max,
                                        appendTo: "body"
                                      }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name", "hourFormat", "minDate", "maxDate"]))
                                    : (_ctx.type === _unref(InputTypes).dropdown)
                                      ? (_openBlock(), _createBlock(_component_Dropdown, {
                                          key: 17,
                                          modelValue: _unref(value),
                                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                                          placeholder: _ctx.placeholder,
                                          class: _normalizeClass(props.class),
                                          readonly: _ctx.readonly,
                                          disabled: _ctx.disabled || _ctx.readonly,
                                          name: _ctx.name,
                                          options: _unref(config).options,
                                          optionLabel: _unref(config).optionLabel,
                                          optionValue: _unref(config).optionValue
                                        }, null, 8, ["modelValue", "placeholder", "class", "readonly", "disabled", "name", "options", "optionLabel", "optionValue"]))
                                      : (_ctx.type === _unref(InputTypes).password)
                                        ? (_openBlock(), _createBlock(_component_Password, {
                                            key: 18,
                                            modelValue: _unref(value),
                                            "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                                            placeholder: _ctx.placeholder,
                                            class: _normalizeClass(props.class),
                                            inputClass: props.class,
                                            disabled: _ctx.disabled || _ctx.readonly,
                                            name: _ctx.name,
                                            feedback: false,
                                            toggleMask: ""
                                          }, null, 8, ["modelValue", "placeholder", "class", "inputClass", "disabled", "name"]))
                                        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                            _createElementVNode("span", _hoisted_6, "The type " + _toDisplayString(_ctx.type) + " isn't a valid Input Type", 1)
                                          ]))
}
}

})