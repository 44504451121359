import { RouteLocationRaw } from "vue-router";
import { User } from "../../models/User";

export interface AuthState {
  user?: User;
  lastRoute: RouteLocationRaw;
  loading: boolean;
}

export const state = (): AuthState => ({
  user: undefined,
  lastRoute: { name: "home" },
  loading: false,
});
