import { computed, ref } from "vue";
import { User } from "../models/User";
import { SidebarMenuItem } from "../models/SidebarMenuItem";
import { hasAllOfAny } from "../utils/helpers";

const accessibleItems = (user: User, raws?: SidebarMenuItem[]) => {
  if (!raws) return raws;

  if (user.admin) return [...raws];

  const accessible: SidebarMenuItem[] = [];

  for (const item of raws) {
    // if for admin must continue
    if (item.admin) {
      continue;
    }

    // validate user has required roles
    if (item.roles && !hasAllOfAny(item.roles, user.roles)) {
      continue;
    }

    // validate user has required permissions
    if (item.permissions && !hasAllOfAny(item.permissions, user.permissions)) {
      continue;
    }

    const items = accessibleItems(user, item.items);

    if (item.to || item.url || items?.length) {
      accessible.push({
        ...item,
        items,
      });
    }
  }

  return accessible;
};

const sidebarMenuItems = ref<SidebarMenuItem[]>([]);
export const useSidebarMenuItems = () => {
  return {
    getSidebarMenuItems: computed(() => sidebarMenuItems),
    setSidebarMenuItems: (raws: SidebarMenuItem[], user?: User) => {
      sidebarMenuItems.value = user ? accessibleItems(user, raws) ?? [] : [];
    },
    clearSidebarMenuItems: () => {
      sidebarMenuItems.value = [];
    },
  };
};
