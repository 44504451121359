<script lang="ts" setup>
import { useSidebarMenuItems } from "../../composables/useSidebarMenuItems";
import MenuItem from "./MenuItem.vue";

const { getSidebarMenuItems } = useSidebarMenuItems();
</script>

<template>
  <ul class="layout-menu">
    <template v-for="(item, i) in getSidebarMenuItems.value" :key="item">
      <MenuItem :item="item" :index="i"></MenuItem>
    </template>
  </ul>
</template>
