import { isOrganization, isAdmin } from "../../../router/guards";

export default {
  name: "organizations",
  redirect: { name: "organizations.profile" },
  children: [
    {
      path: "profile",
      name: "organizations.profile",
      component: () => import(/* webpackChunkName: "restaurant-order-report" */ "../views/OrganizationProfile.vue"),
      beforeEnter: [isOrganization, isAdmin],
    },
    {
      path: "users",
      name: "organizations.users",
      component: () => import(/* webpackChunkName: "restaurant-users" */ "../views/OrganizationUsers.vue"),
      beforeEnter: [isOrganization, isAdmin],
    },
  ],
};
