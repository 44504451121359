import store from "../store";

export const isSystem = () => store.getters["auth/isSystem"];

export const isOrganization = () => !store.getters["auth/isSystem"];

export const isAdmin = () => store.getters["auth/isAdmin"];

export const isFinance = () => store.getters["auth/isAdmin"] || store.getters["auth/isFinance"];

export const isSupport = () => store.getters["auth/isAdmin"] || store.getters["auth/isSupport"];

export const isLogin = () => store.getters["auth/isLogin"];

export const isBusiness = () => store.getters["auth/isBusiness"];

export const isSupplier = () => !store.getters["auth/isBusiness"];
