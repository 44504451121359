import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid m-0 p-0 surface-login-split flex flex-column align-items-center justify-content-center h-screen w-screen overflow-hidden" }
const _hoisted_2 = {
  class: "login-card max-w-full max-h-full overflow-hidden mt-auto md:border-noround-left",
  style: {"width":"960px","height":"540px"}
}
const _hoisted_3 = { class: "flex align-items-center justify-content-center h-full w-full" }
const _hoisted_4 = { class: "grid w-full h-full" }

import Modal from "../../components/Modal/components/Modal.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Layout',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_view)
          ])
        ])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mt-auto mb-2 flex align-items-center justify-content-center" }, [
        _createElementVNode("span", { class: "text-sm" }, "Powered by"),
        _createElementVNode("img", {
          src: "/assets/logoVT.png",
          alt: "logo",
          class: "ml-1 w-6rem flex-shrink-0"
        })
      ], -1))
    ]),
    _createVNode(Modal)
  ], 64))
}
}

})