<script setup lang="ts">
import { useModal } from "../composables/useModal";
import severityToColor from "../../../consts/severityToColor";

// Tod: add event to template en intranet4
const { component, props, severity, header, openPosition, maximizable, visible, width, onclose, events, closable } = useModal();
</script>

<template>
  <Dialog v-model:visible="visible" :position="openPosition" :maximizable="maximizable" :closable="closable" :style="{ width }" :modal="true" class="modal" @after-hide="onclose">
    <template #header>
      <div :class="`flex modal-header w-full bg-${severityToColor[severity]}`">
        <span class="m-auto font-medium text-4xl line-height-2">{{ header }}</span>
      </div>
    </template>

    <component :is="component" v-bind="props" v-on="events"></component>
  </Dialog>
</template>

<style lang="scss">
.modal {
  .p-dialog-header {
    padding: 0 !important;
    display: block;

    .p-dialog-header-icons {
      background-color: transparent !important;
      padding-right: 1.5rem;
      position: absolute;
      top: 8px;
      right: 0;
    }

    .modal-header {
      padding-left: 1.5rem;
      padding-top: 1.5rem;
      padding-right: 0;
      padding-bottom: 1.5rem;
      margin: 0;
      border-top-left-radius: 0.6rem;
      border-top-right-radius: 0.6rem;
    }
  }
}
</style>
