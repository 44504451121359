import { ValidatorFn } from "../consts/Validators";

export type OnChangeFn<T> = (value?: T) => void;

/**
 * Enum of input types
 *
 * @author: Josué Cruz <jicruz@voiceteam.com>
 * @description: The FormControl class is a generic class that provides a way to manage form controls in a reactive way. It allows for setting and getting the value of the control, adding and removing validators, validating the control, and subscribing to changes in the control's value. It also provides functionality for resetting the control and tracking whether the control has been touched or not.
 * @version: 1.0
 * @since: 2023-06-26
 * @copyright: Voiceteam Call
 */
export class FormControl<T> {
  private _value?: T;
  private _validators: ValidatorFn<T>[] = [];
  private _errors: string[] = [];
  private _touch: boolean;
  private _showError = false;
  private _onChange = new Set<OnChangeFn<T>>();

  public get value() {
    return this._value;
  }

  public set value(_value: T | undefined) {
    this._touch = true;

    this.updateValue(_value);
  }

  public get invalid() {
    return this._errors.length > 0;
  }

  public get showError() {
    return this._showError;
  }

  public get errors() {
    return [...this._errors];
  }

  public constructor(config?: { value?: T; validators?: ValidatorFn<T>[]; touch?: boolean }) {
    this._validators = config?.validators ?? [];
    this._touch = config?.touch ?? false;

    this.updateValue(config?.value);
  }

  private updateShowError() {
    this._showError = this._touch && this._errors.length > 0;
  }

  public addValidator(validator: ValidatorFn<T>) {
    this._validators.push(validator);

    this.validate();
  }

  public removeValidator(validator: ValidatorFn<T>) {
    const index = this._validators.indexOf(validator);

    if (index !== -1) {
      this._validators.splice(index, 1);
      this.validate();
    }
  }

  public validate() {
    const errors: string[] = [];

    for (const validator of this._validators) {
      const invalid = validator(this._value);

      if (invalid) errors.push(invalid);
    }

    this._errors = errors;

    return !this.invalid;
  }

  public touch() {
    this._touch = true;

    this.updateShowError();
  }

  public reset() {
    this._touch = false;

    this.updateValue();
  }

  public updateValue(_value?: T) {
    this._value = _value;

    this.validate();

    this.updateShowError();

    for (const fn of this._onChange) {
      fn(this._value);
    }
  }

  public subscribe(fn: OnChangeFn<T>) {
    this._onChange.add(fn);
    return () => this._onChange.delete(fn);
  }
}
