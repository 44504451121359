import { Severities } from "../enums/Severities";

const severityToColor: { [key: string]: string } = {};

severityToColor[Severities.Primary] = "primary";
severityToColor[Severities.Secondary] = "bluegray-500";
severityToColor[Severities.Success] = "green-500";
severityToColor[Severities.Info] = "blue-500";
severityToColor[Severities.Warning] = "yellow-200";
severityToColor[Severities.Help] = "purple-500";
severityToColor[Severities.Danger] = "red-500";

export default severityToColor;
