import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-sidebar" }
const _hoisted_2 = { class: "layout-main-container" }
const _hoisted_3 = { class: "layout-main" }

import { ref, computed, watch, Ref } from "vue";
import { useLayout } from "./composables/Layout";

import Topbar from "./Topbar.vue";
import Sidebar from "./Sidebar.vue";

import Modal from "../../components/Modal";


export default /*@__PURE__*/_defineComponent({
  __name: 'Layout',
  setup(__props) {

const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const outsideClickListener: Ref<null | ((event: MouseEvent) => void)> = ref(null);

const containerClass = computed(() => {
  return {
    "layout-theme-light": !layoutConfig.value.darkTheme,
    "layout-theme-dark": layoutConfig.value.darkTheme,
    "layout-overlay": layoutConfig.value.menuMode === "overlay",
    "layout-static": layoutConfig.value.menuMode === "static",
    "layout-static-inactive": layoutState.value.staticMenuDesktopInactive && layoutConfig.value.menuMode === "static",
    "layout-overlay-active": layoutState.value.overlayMenuActive,
    "layout-mobile-active": layoutState.value.staticMenuMobileActive,
    "p-ripple-disabled": !layoutConfig.value.ripple,
  };
});

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        layoutState.value.overlayMenuActive = false;
        layoutState.value.staticMenuMobileActive = false;
        layoutState.value.menuHoverActive = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};

const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener.value);
    outsideClickListener.value = null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isOutsideClicked = (event: any) => {
  const sidebarEl = document.querySelector(".layout-sidebar");
  const topbarEl = document.querySelector(".layout-menu-button");

  return !(sidebarEl?.isSameNode(event.target) || sidebarEl?.contains(event.target) || topbarEl?.isSameNode(event.target) || topbarEl?.contains(event.target));
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["layout-wrapper", containerClass.value])
    }, [
      _createVNode(Topbar),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(Sidebar)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_view)
        ])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "layout-mask" }, null, -1))
    ], 2),
    _createVNode(_unref(Modal))
  ], 64))
}
}

})