import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"


import { useSelectInput } from "../composables/useSelectInput";

declare type SelectInputProp = {
  /**
   * a space-separated list of the case-sensitive classes of the element.
   */
  class?: string;

  /**
   * a boolean which indicates whether the control is disabled.
   */
  disabled?: boolean;

  /**
   * a boolean which indicates if the control has search area.
   */
  filter?: boolean;

  /**
   * Maximum number of selectable items. <= 0 to unlimited
   */
  limit?: number;

  /**
   * Whether to show the header checkbox to toggle the selection of all items at once.
   */
  maxSelectedLabels?: number;

  /**
   * for v-model support
   */
  modelValue?: unknown | unknown[];

  /**
   * name of element
   */
  name?: string;

  /**
   * Property name or getter function to use as the label of an option.
   */
  optionLabel?: string | ((data: unknown) => string);

  /**
   * An array of items to display as the available options.
   */
  options?: { [key: string]: any }[];

  /**
   * Property name or getter function to use as the value of an option, defaults to the option itself when not defined.
   */
  optionValue?: string | ((data: unknown) => unknown);

  /**
   * defines the text displayed in a form control when the control has no value.
   */
  placeholder?: string;

  /**
   * when present, makes the element not mutable, meaning the user can not edit the control
   */
  readonly?: boolean;

  /**
   * load options items from get url
   */
  url?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'SelectInput',
  props: {
    class: {},
    disabled: { type: Boolean, default: false },
    filter: { type: Boolean, default: true },
    limit: { default: 1 },
    maxSelectedLabels: {},
    modelValue: {},
    name: {},
    optionLabel: {},
    options: {},
    optionValue: {},
    placeholder: {},
    readonly: { type: Boolean, default: false },
    url: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { select, loading, selectOptions, value, hide } = useSelectInput(props, emit);

return (_ctx: any,_cache: any) => {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createBlock(_component_MultiSelect, {
    ref_key: "select",
    ref: select,
    modelValue: _unref(value),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
    placeholder: _ctx.placeholder,
    class: _normalizeClass(props.class),
    disabled: _ctx.disabled,
    readonly: _ctx.readonly,
    name: _ctx.name,
    options: _unref(selectOptions),
    optionLabel: _ctx.optionLabel ?? 'label',
    optionValue: _ctx.optionValue ?? 'key',
    selectionLimit: _ctx.limit ?? 1,
    loading: _unref(loading),
    maxSelectedLabels: _ctx.maxSelectedLabels,
    filter: _ctx.filter,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_unref(hide)($event)))
  }, null, 8, ["modelValue", "placeholder", "class", "disabled", "readonly", "name", "options", "optionLabel", "optionValue", "selectionLimit", "loading", "maxSelectedLabels", "filter"]))
}
}

})