import { Filter } from "../interfaces/Filter";
import { computed } from "vue";
import filterMethodsNames from "../consts/filterMethodsNames";
import { Severities } from "../../../enums/Severities";
import { basicTypeToString } from "../../../utils/helpers";
import { FilterMethods } from "../enums/FilterMethods";

export const useFilterRender = (props: { filter?: Filter; severity?: Severities }) => {
  return {
    propLabel: computed(() => {
      if (props.filter) {
        return props.filter.label;
      }

      return "";
    }),
    method: computed(() => {
      if (props.filter) {
        return filterMethodsNames[props.filter.method];
      }

      return "";
    }),
    value: computed(() => {
      if (props.filter) {
        if (props.filter.method === FilterMethods.between && Array.isArray(props.filter.value)) return `${basicTypeToString(props.filter.value[0], ["True", "False"])} and ${basicTypeToString(props.filter.value[1], ["True", "False"])}`;

        if (props.filter.options && props.filter.options)
          if (Array.isArray(props.filter.value))
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return props.filter.value.map((value) => props.filter?.options?.find((option) => option[props.filter?.keyField ?? "key"] === value)![props.filter?.labelField ?? "label"]);
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          else return props.filter.options.find((option) => option[props.filter?.keyField ?? "key"] === props.filter?.value)![props.filter?.labelField ?? "label"];

        if (Array.isArray(props.filter.value)) return props.filter.value.map((value) => basicTypeToString(value, ["True", "False"])).join(",");

        return basicTypeToString(props.filter.value, ["True", "False"]);
      }

      return "";
    }),
  };
};
