import { computed, ref } from "vue";
import { useStore } from "vuex";
import { ToastServiceMethods } from "primevue/toastservice";
import { StateInterface } from "../store";
import { User } from "../models/User";
import { PushRouter } from "../store/auth/actions";

export const useAuthStore = () => {
  const store = useStore<StateInterface>();
  const checking = ref(false);

  return {
    // States
    user: computed(() => store.state.auth.user),
    checking,

    // Getters
    isAdmin: computed(() => store.getters["auth/isAdmin"]),
    isLogin: computed(() => store.getters["auth/isLogin"]),
    isBusiness: computed(() => store.getters["auth/isBusiness"]),
    waiting: computed(() => store.getters["auth/isLoading"]),

    // Mutations
    setUser: (user: User) => store.commit("auth/setUser", user),
    unsetUser: () => store.commit("auth/unsetUser"),

    // Actions
    checkAuth: async (router: PushRouter) => {
      checking.value = true;

      // Todo: verify in production
      // checkCookie("voicetasty_api_session")
      // if (checkCookie("XSRF-TOKEN")) {
      await store.dispatch("auth/getMe", { router });
      // }

      checking.value = false;
    },
    signIn: (email: string, password: string, toast: ToastServiceMethods, router: PushRouter): Promise<void> => store.dispatch("auth/signIn", { email, password, toast, router }),
    signOut: (toast: ToastServiceMethods, router: PushRouter): Promise<void> => store.dispatch("auth/signOut", { toast, router }),
  };
};
