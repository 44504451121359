import { isBusiness, isSupplier } from "../../../router/guards";

export default {
  name: "reports",
  children: [
    {
      path: "restaurants",
      name: "restaurants.reports",
      redirect: { name: "restaurants.reports.orders" },
      children: [
        {
          path: "orders",
          name: "restaurants.reports.orders",
          component: () => import(/* webpackChunkName: "restaurant-order-report" */ "../views/RestaurantOrder.vue"),
        },
        {
          path: "deliveries",
          name: "restaurants.reports.deliveries",
          component: () => import(/* webpackChunkName: "restaurant-order-delivery" */ "../views/DeliveryOrders.vue"),
        },
      ],
      beforeEnter: [isSupplier],
    },
    {
      path: "businesses",
      name: "businesses.reports",
      redirect: { name: "businesses.reports.orders" },
      children: [
        {
          path: "orders",
          name: "businesses.reports.orders",
          component: () => import(/* webpackChunkName: "restaurant-order-report" */ "../views/BusinessesOrder.vue"),
        },
        {
          path: "details",
          name: "businesses.reports.details",
          component: () => import(/* webpackChunkName: "restaurant-order-report" */ "../views/BusinessesDetails.vue"),
        },
      ],
      beforeEnter: [isBusiness],
    },
    {
      path: "users",
      name: "users.reports",
      redirect: { name: "users.reports.orders" },
      children: [
        {
          path: "orders",
          name: "users.reports.orders",
          component: () => import(/* webpackChunkName: "restaurant-order-report" */ "../views/UserOrder.vue"),
        },
      ],
      beforeEnter: [isBusiness],
    },
  ],
};
