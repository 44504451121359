<script lang="ts" setup>
import { ref, computed, watch, Ref } from "vue";
import { useLayout } from "./composables/Layout";

import Topbar from "./Topbar.vue";
import Sidebar from "./Sidebar.vue";

import Modal from "../../components/Modal";

const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const outsideClickListener: Ref<null | ((event: MouseEvent) => void)> = ref(null);

const containerClass = computed(() => {
  return {
    "layout-theme-light": !layoutConfig.value.darkTheme,
    "layout-theme-dark": layoutConfig.value.darkTheme,
    "layout-overlay": layoutConfig.value.menuMode === "overlay",
    "layout-static": layoutConfig.value.menuMode === "static",
    "layout-static-inactive": layoutState.value.staticMenuDesktopInactive && layoutConfig.value.menuMode === "static",
    "layout-overlay-active": layoutState.value.overlayMenuActive,
    "layout-mobile-active": layoutState.value.staticMenuMobileActive,
    "p-ripple-disabled": !layoutConfig.value.ripple,
  };
});

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        layoutState.value.overlayMenuActive = false;
        layoutState.value.staticMenuMobileActive = false;
        layoutState.value.menuHoverActive = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};

const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener.value);
    outsideClickListener.value = null;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isOutsideClicked = (event: any) => {
  const sidebarEl = document.querySelector(".layout-sidebar");
  const topbarEl = document.querySelector(".layout-menu-button");

  return !(sidebarEl?.isSameNode(event.target) || sidebarEl?.contains(event.target) || topbarEl?.isSameNode(event.target) || topbarEl?.contains(event.target));
};
</script>

<template>
  <div class="layout-wrapper" :class="containerClass">
    <Topbar></Topbar>
    <div class="layout-sidebar">
      <Sidebar></Sidebar>
    </div>
    <div class="layout-main-container">
      <div class="layout-main">
        <router-view></router-view>
      </div>
    </div>
    <div class="layout-mask"></div>
  </div>

  <Modal></Modal>
</template>

<style lang="scss" scoped></style>
