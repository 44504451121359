import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = {
  key: 1,
  class: "col-12 flex"
}


import { DynamicFormField } from "../interfaces/DynamicFormField";
import { useDynamicForm } from "../composables/useDynamicForm";
import DynamicInput from "../../DynamicInput/components/DynamicInput.vue";

// Todo: add documentation

export default /*@__PURE__*/_defineComponent({
  __name: 'DynamicForm',
  props: {
    fields: {},
    submittable: { type: Boolean, default: true },
    submitText: { default: "Send" },
    submitIcon: { default: "pi pi-check" },
    cancelable: { type: Boolean, default: true },
    cancelText: { default: "Cancel" },
    cancelIcon: { default: "pi pi-times" },
    mdCol: { default: 12 },
    lgCol: { default: 12 },
    xlCol: { default: 12 },
    excludeUndefined: { type: Boolean, default: false },
    excludeReadonly: { type: Boolean, default: false },
    excludeDisabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  emits: ["cancel", "submit", "change"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { form, submit } = useDynamicForm(props, emit);

return (_ctx: any,_cache: any) => {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(`field col md:col-${_ctx.mdCol ?? 12} lg:col-${_ctx.lgCol ?? 12} xl:col-${_ctx.xlCol ?? 12}`),
        key: field.key
      }, [
        _createElementVNode("label", null, _toDisplayString(field.label), 1),
        _createVNode(DynamicInput, {
          readonly: field.readonly,
          disabled: field.disabled || _ctx.loading,
          name: field.key,
          class: _normalizeClass(`w-full ${_unref(form).fields[field.key].showError ? 'p-invalid' : ''}`),
          modelValue: _unref(form).fields[field.key].value,
          "onUpdate:modelValue": ($event: any) => ((_unref(form).fields[field.key].value) = $event),
          type: field.type,
          placeholder: field.placeholder,
          text: field.text,
          int: field.int,
          float: field.float,
          date: field.date,
          datetime: field.datetime,
          select: field.select,
          radio: field.radio,
          checkbox: field.checkbox,
          currency: field.currency,
          file: field.file,
          files: field.files,
          color: field.color,
          rating: field.rating,
          time: field.time,
          dropdown: field.dropdown
        }, null, 8, ["readonly", "disabled", "name", "class", "modelValue", "onUpdate:modelValue", "type", "placeholder", "text", "int", "float", "date", "datetime", "select", "radio", "checkbox", "currency", "file", "files", "color", "rating", "time", "dropdown"]),
        (_unref(form).fields[field.key].showError)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(form).fields[field.key].errors, (error, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "text-sm text-red-500"
              }, _toDisplayString(error), 1))
            }), 128))
          : _createCommentVNode("", true)
      ], 2))
    }), 128)),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ProgressBar, {
            mode: "indeterminate",
            style: {"height":"8px"}
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_ctx.cancelable)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 0,
                severity: "secondary",
                class: "p-ripple ml-2 mr-auto",
                type: "button",
                label: _ctx.cancelText,
                icon: _ctx.cancelIcon,
                "icon-pos": "left",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('cancel')))
              }, null, 8, ["label", "icon"])), [
                [_directive_ripple]
              ])
            : _createCommentVNode("", true),
          (_ctx.submittable)
            ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
                key: 1,
                class: "p-ripple ml-auto mr-2",
                type: "button",
                label: _ctx.submitText,
                icon: _ctx.submitIcon,
                "icon-pos": "left",
                onClick: _unref(submit)
              }, null, 8, ["label", "icon", "onClick"])), [
                [_directive_ripple]
              ])
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})