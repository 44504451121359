import { InputTypes } from "../../../components/DynamicInput";
import { FilterItemTypes } from "../enums/FilterItemTypes";

const filterItemTypeToInputType: { [key: number]: InputTypes } = {};

filterItemTypeToInputType[FilterItemTypes.text] = InputTypes.text;
filterItemTypeToInputType[FilterItemTypes.int] = InputTypes.int;
filterItemTypeToInputType[FilterItemTypes.float] = InputTypes.float;
filterItemTypeToInputType[FilterItemTypes.boolean] = InputTypes.boolean;
filterItemTypeToInputType[FilterItemTypes.date] = InputTypes.date;
filterItemTypeToInputType[FilterItemTypes.datetime] = InputTypes.datetime;
filterItemTypeToInputType[FilterItemTypes.select] = InputTypes.select;
filterItemTypeToInputType[FilterItemTypes.tristate] = InputTypes.tristate;

export default filterItemTypeToInputType;
