<script lang="ts" setup>
window.addEventListener("afterprint", () => self.close);
</script>

<template>
  <router-view></router-view>
</template>

<style lang="scss">
@page {
  size: letter portrait;
}

@media print {
  .no-print {
    display: none;
  }
}
</style>
