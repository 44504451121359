import { GetterTree } from "vuex";
import { CartState } from "./state";
import { StateInterface } from "../index";
import { calculatedCartItem } from "../../utils/helpers";

const getters: GetterTree<CartState, StateInterface> = {
  calculatedItems(state) {
    return state.items.map((item) => calculatedCartItem(item));
  },
  isLoading(state) {
    return state.loading;
  },
};

export default getters;
