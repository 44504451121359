import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import env from "../env.json";
import { ApiErrorResponse, ApiResponse } from "../interfaces/Api";
import { readBlobAsJson } from "../utils/helpers";

const axiosInstance = axios.create({
  withCredentials: true,
  withXSRFToken: true,
});

export class Http {
  private _config: AxiosRequestConfig<unknown>;
  private handle: (error: AxiosError) => AxiosResponse | undefined;

  private makeConfig(config?: AxiosRequestConfig<unknown>): AxiosRequestConfig<unknown> {
    return { ...this._config, ...config, baseURL: env.API_URL };
  }

  constructor(data?: { config?: AxiosRequestConfig<unknown>; handle?: (error: AxiosError) => AxiosResponse }) {
    this._config = data?.config ?? {};

    this.handle = data?.handle ?? ((error: AxiosError) => error.response);
  }

  private async request<T>(request: () => Promise<AxiosResponse<T>>): Promise<AxiosResponse<T>> {
    try {
      return await request();
    } catch (error) {
      if (error && error instanceof AxiosError) {
        if (this.handle) {
          const handle = this.handle(error);

          if (handle) {
            return handle;
          }
        }
      }

      return Promise.reject(error);
    }
  }

  public download(uri: string, config?: AxiosRequestConfig) {
    return this.request<Blob | ApiErrorResponse<{ [key: string]: string | string[] }>>(() => {
      return new Promise((resolve) => {
        axiosInstance
          .get(uri, this.makeConfig({ ...config, responseType: "blob" }))
          .then((response) => resolve(response))
          .catch(async (error) => resolve({ ...error.response, data: await readBlobAsJson(error.response.data) }));
      });
    });
  }

  // public download<D>(uri: string, config?: AxiosRequestConfig<D>) {
  //   return this.request<Blob | ApiErrorResponse<D>>(() => {
  //     return new Promise((resolve) => {
  //       axiosInstance
  //         .get(uri, this.makeConfig({ ...config }))
  //         .then((response) => {
  //           return resolve({ ...response, data: new Blob([response.data], { type: response.headers["content-type"] }) });
  //         })
  //         .catch((error) => resolve(error.response));
  //     });
  //   });
  // }

  public get<T>(uri: string, config?: AxiosRequestConfig) {
    return this.request<ApiResponse<T>>(() => axiosInstance.get(uri, this.makeConfig(config)));
  }

  public post<T>(uri: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.request<ApiResponse<T>>(() => axiosInstance.post(uri, data, this.makeConfig(config)));
  }

  public put<T>(uri: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.request<ApiResponse<T>>(() => axiosInstance.put(uri, data, this.makeConfig(config)));
  }

  public patch<T>(uri: string, data?: unknown, config?: AxiosRequestConfig) {
    return this.request<ApiResponse<T>>(() => axiosInstance.patch(uri, data, this.makeConfig(config)));
  }

  public async delete<T>(uri: string, config?: AxiosRequestConfig) {
    return this.request<ApiResponse<T>>(() => axiosInstance.delete(uri, this.makeConfig(config)));
  }
}
