<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from "vue";
import FilterRender from "./FilterRender.vue";
import { FilterItem } from "../interfaces/FilterItem";
import { Filter } from "../interfaces/Filter";
import FilterForm from "./FilterForm.vue";
import OverlayPanel from "primevue/overlaypanel";
import { useFilter } from "../composables/useFilter";
import { Severities } from "../../../enums/Severities";
import severityToColor from "../../../consts/severityToColor";
import { useRouter } from "vue-router";

const props = withDefaults(
  defineProps<{
    items: FilterItem[];
    severity?: Severities;
  }>(),
  {
    severity: Severities.Primary,
  },
);

const emit = defineEmits<{
  (e: "search", filters: Filter[]): void;
}>();

const router = useRouter();
const routeName = router.currentRoute.value.name?.toString() ?? router.currentRoute.value.fullPath;

const { apply, filters, op, elements, applyFilter, removeFilter, search, toggle } = useFilter(routeName, props, emit);
</script>

<template>
  <div :class="'grid mx-1 my-2 border-1 border-' + severityToColor[severity ?? Severities.Primary]" style="min-height: 3rem">
    <div class="col-12 md:col-10">
      <div class="flex">
        <div class="grid m-0">
          <FilterRender v-for="filter of filters" :key="`render-${filter.identifier ?? filter.key}`" :filter="filter" :severity="severity ?? Severities.Primary" @remove="removeFilter"></FilterRender>
        </div>

        <Button label="Add condition" icon="pi pi-plus" iconPos="left" :class="'p-ripple my-auto mx-2 p-button-text p-button-' + severity" @click="toggle" v-ripple></Button>
      </div>
    </div>

    <div class="col-12 md:col-2">
      <div class="flex">
        <Button label="Apply" icon="pi pi-search" iconPos="left" :class="'p-ripple ml-auto mr-2 my-auto p-button-' + (severity ?? Severities.Primary)" :disabled="apply" @click="search" v-ripple></Button>
      </div>
    </div>
  </div>

  <OverlayPanel ref="op" :showCloseIcon="false" style="width: 428px">
    <FilterForm :fields="elements" @apply="applyFilter" :severity="severity ?? Severities.Primary"></FilterForm>
  </OverlayPanel>
</template>

<style scoped lang="scss"></style>
