import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "col-12 md:col-10" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "grid m-0" }
const _hoisted_4 = { class: "col-12 md:col-2" }
const _hoisted_5 = { class: "flex" }


import FilterRender from "./FilterRender.vue";
import { FilterItem } from "../interfaces/FilterItem";
import { Filter } from "../interfaces/Filter";
import FilterForm from "./FilterForm.vue";
import OverlayPanel from "primevue/overlaypanel";
import { useFilter } from "../composables/useFilter";
import { Severities } from "../../../enums/Severities";
import severityToColor from "../../../consts/severityToColor";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'Filter',
  props: {
    items: {},
    severity: { default: Severities.Primary }
  },
  emits: ["search"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const router = useRouter();
const routeName = router.currentRoute.value.name?.toString() ?? router.currentRoute.value.fullPath;

const { apply, filters, op, elements, applyFilter, removeFilter, search, toggle } = useFilter(routeName, props, emit);

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass('grid mx-1 my-2 border-1 border-' + _unref(severityToColor)[_ctx.severity ?? _unref(Severities).Primary]),
      style: {"min-height":"3rem"}
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(filters), (filter) => {
              return (_openBlock(), _createBlock(FilterRender, {
                key: `render-${filter.identifier ?? filter.key}`,
                filter: filter,
                severity: _ctx.severity ?? _unref(Severities).Primary,
                onRemove: _unref(removeFilter)
              }, null, 8, ["filter", "severity", "onRemove"]))
            }), 128))
          ]),
          _withDirectives(_createVNode(_component_Button, {
            label: "Add condition",
            icon: "pi pi-plus",
            iconPos: "left",
            class: _normalizeClass('p-ripple my-auto mx-2 p-button-text p-button-' + _ctx.severity),
            onClick: _unref(toggle)
          }, null, 8, ["class", "onClick"]), [
            [_directive_ripple]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createVNode(_component_Button, {
            label: "Apply",
            icon: "pi pi-search",
            iconPos: "left",
            class: _normalizeClass('p-ripple ml-auto mr-2 my-auto p-button-' + (_ctx.severity ?? _unref(Severities).Primary)),
            disabled: _unref(apply),
            onClick: _unref(search)
          }, null, 8, ["class", "disabled", "onClick"]), [
            [_directive_ripple]
          ])
        ])
      ])
    ], 2),
    _createVNode(_unref(OverlayPanel), {
      ref_key: "op",
      ref: op,
      showCloseIcon: false,
      style: {"width":"428px"}
    }, {
      default: _withCtx(() => [
        _createVNode(FilterForm, {
          fields: _unref(elements),
          onApply: _unref(applyFilter),
          severity: _ctx.severity ?? _unref(Severities).Primary
        }, null, 8, ["fields", "onApply", "severity"])
      ]),
      _: 1
    }, 512)
  ], 64))
}
}

})