import MultiSelect, { MultiSelectChangeEvent } from "primevue/multiselect";
import { SelectInputProp } from "../interfaces/SelectInputProp";
import { computed, ref, watch } from "vue";
import { Http } from "../../../services/Http";

export const useSelectInput = (props: Readonly<SelectInputProp>, emit: (e: "update:modelValue", value: unknown | unknown[] | undefined) => void) => {
  const select = ref<MultiSelect | undefined>();
  const loading = ref(false);
  const _urlOptions = ref<{ [key: string]: unknown }[]>([]);
  const _propOptions = ref<{ [key: string]: unknown }[]>([]);

  const getOptionFromUrl = async (url?: string) => {
    loading.value = true;

    if (url) {
      const http = new Http();

      try {
        const { data } = await http.get<{ [key: string]: unknown }[]>(url);

        // Todo: add wrapper option
        if (data.success) {
          _urlOptions.value = [...data.entries];
        }
      } catch (error) {
        console.error(error); // Todo: error manager
      }
    }

    loading.value = false;
  };

  const getOptionFromPProp = (options?: { [key: string]: unknown }[]) => {
    _propOptions.value = options ?? [];
  };

  getOptionFromUrl(props.url);
  watch(() => props.url, getOptionFromUrl);

  getOptionFromPProp(props.options);
  watch(() => props.options, getOptionFromPProp);

  return {
    select,
    loading,
    selectOptions: computed(() => (props.url ? _urlOptions.value : _propOptions.value)),
    value: computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    }),
    hide: (event: MultiSelectChangeEvent) => {
      if (event.value && Array.isArray(event.value) && event.value.length === (props.limit ?? 1)) {
        select.value?.hide();
      }
    },
  };
};
