import { RouteRecordRaw } from "vue-router";
import { isSupplier, isSupport } from "../../../router/guards";

const routes: RouteRecordRaw[] = [
  {
    path: "reports/restaurants/deliveries/print",
    name: "restaurants.reports.deliveries.print",
    component: () => import(/* webpackChunkName: "restaurant-order-delivery-print" */ "../views/DeliveriesOrdersPrint.vue"),
    props: (route) => ({
      date: route.query.date,
      business_id: route.query.business_id ? parseInt(route.query.business_id.toString()) : undefined,
      delivery_at: route.query.delivery_at,
    }),
    beforeEnter: [isSupplier, isSupport],
  },
];

export default routes;
