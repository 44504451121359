import { Plate, PlateOptionValue } from "../../models/Plate";

export interface MenuPlate extends Plate {
  menu_id: number;
}
export interface CartItem extends MenuPlate {
  quantity: number;
  selectedOptionValues: (PlateOptionValue | PlateOptionValue[] | undefined)[];
  index?: number;
}

export interface CalculatedCartItem extends CartItem {
  taxFactor: number;
  subtotal: number;
}

export interface CartState {
  items: CartItem[];
  loading: boolean;
}

export const state = (): CartState => ({
  items: [],
  loading: false,
});
