import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "font-bold" }


import { Filter } from "../interfaces/Filter";
import { useFilterRender } from "../composables/useFilterRender";
import { Severities } from "../../../enums/Severities";
import severityToColor from "../../../consts/severityToColor";


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterRender',
  props: {
    filter: {},
    severity: { default: Severities.Primary }
  },
  emits: ["remove"],
  setup(__props: any) {

const props = __props;



const { propLabel, method, value } = useFilterRender(props);

return (_ctx: any,_cache: any) => {
  const _component_Chip = _resolveComponent("Chip")!

  return (_ctx.filter)
    ? (_openBlock(), _createBlock(_component_Chip, {
        key: 0,
        removable: true,
        class: "m-1",
        onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('remove', _ctx.filter)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: _normalizeClass(`font-bold text-${_unref(severityToColor)[_ctx.severity]}`)
          }, _toDisplayString(_unref(propLabel)), 3),
          _createElementVNode("span", null, " " + _toDisplayString(_unref(method)) + " ", 1),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(value)), 1)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})