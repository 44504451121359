/* istanbul ignore file */
/* this file is only to export components, enums and interfaces */
import component from "./components/Filter.vue";

// Interfaces
export { Filter } from "./interfaces/Filter";
export { FilterItem } from "./interfaces/FilterItem";

// Enums
export { FilterItemTypes } from "./enums/FilterItemTypes";
export { FilterMethods } from "./enums/FilterMethods";

export default component;
