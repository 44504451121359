import { createRouter, createWebHistory } from "vue-router";
import { RouteRecordRaw } from "vue-router";
import { isLogin, isOrganization } from "./guards";
import Layout from "../layouts/main/Layout.vue";
import AuthLayout from "../layouts/auth/Layout.vue";
import PrintLayout from "../layouts/print/Layout.vue";
import dashboard from "../modules/dashboard/router";
import supplierRoutes from "../modules/supplier/router";
import businessRoutes from "../modules/business/router";
import reportRoutes from "../modules/reports/router";
import profileRoutes from "../modules/profile/router";
import organizationRoutes from "../modules/organizations/router";
import printRoutes from "../modules/prints/router";
import { setLastPage } from "./auxiliars";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "auth",
    component: AuthLayout,
    redirect: { name: "login" },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
        beforeEnter: () => {
          return isLogin() ? (isOrganization() ? { name: "home" } : { name: "access-denied" /* Todo: redirect to system area */ }) : true;
        },
      },
      {
        path: "forgot-password",
        name: "forgot-password",
        component: () => import(/* webpackChunkName: "forgot-password" */ "../views/RequestPasswordReset.vue"),
        beforeEnter: () => {
          return isLogin() ? { name: "home" } : true;
        },
      },
      {
        path: "password-reset/:token",
        name: "password-reset",
        component: () => import(/* webpackChunkName: "password-reset" */ "../views/ChangePasswordReset.vue"),
        props: (route) => ({ token: route.params.token, ...route.query }),
        beforeEnter: () => {
          return isLogin() ? { name: "home" } : true;
        },
      },
    ],
  },
  {
    path: "",
    name: "home",
    component: Layout,
    redirect: { name: "dashboard" },
    children: [
      {
        path: "",
        ...dashboard,
      },
      ...supplierRoutes,
      ...businessRoutes,
      {
        path: "reports",
        ...reportRoutes,
      },
      {
        path: "profile",
        ...profileRoutes,
      },
      {
        path: "organizations",
        ...organizationRoutes,
      },
    ],
    beforeEnter: (route) => {
      setLastPage(route);

      return isLogin() ? (isOrganization() ? true : { name: "access-denied" /* Todo: redirect to system area */ }) : { name: "login" };
    },
  },
  {
    path: "/print",
    name: "print",
    component: PrintLayout,
    redirect: { name: "home" },
    children: printRoutes,
    beforeEnter: (route) => {
      setLastPage(route);
    },
  },
  {
    path: "/success",
    name: "success",
    component: () => import(/* webpackChunkName: "success" */ "../views/Success.vue"),
    props: (route) => ({ ...route.query }),
    beforeEnter: (route) => {
      setLastPage(route);
    },
  },
  {
    path: "/error",
    name: "error",
    component: () => import(/* webpackChunkName: "error" */ "../views/Error.vue"),
    beforeEnter: (route) => {
      setLastPage(route);
    },
  },
  {
    path: "/access-denied",
    name: "access-denied",
    component: () => import(/* webpackChunkName: "error" */ "../views/AccessDenied.vue"),
    beforeEnter: (route) => {
      setLastPage(route);
    },
  },
  {
    path: "/:pathMMatch(.*)*",
    name: "not-found",
    component: () => import(/* webpackChunkName: "login" */ "../views/NotFound.vue"),
    beforeEnter: (route) => {
      setLastPage(route);
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
