import { watch } from "vue";
import { DynamicFormComponentProp, DynamicFormEmits } from "../interfaces/DynamicFormComponentProp";
import { DynamicFormField } from "../interfaces/DynamicFormField";
import { useFormGroup, useFormGroupField } from "../../../composables/useFormGroup";

export const dynamicFormFieldToUseFormGroupField = (fields: DynamicFormField<unknown>[]): useFormGroupField => {
  const useFormGroupField: useFormGroupField = {};

  for (const field of fields) {
    useFormGroupField[field.key] = { value: field.value, validators: field.validators };
  }

  return useFormGroupField;
};

export const useDynamicForm = (props: Readonly<DynamicFormComponentProp>, emit: DynamicFormEmits) => {
  const form = useFormGroup(dynamicFormFieldToUseFormGroupField(props.fields));
  const onchange = (key: string, value: unknown) => emit("change", { key, value });

  form.value.subscribe(onchange);
  watch(
    () => props.fields,
    () => {
      const _form = useFormGroup(dynamicFormFieldToUseFormGroupField(props.fields));

      form.value.unsubscribe();
      form.value = _form.value;
      form.value.subscribe(onchange);
    },
  );

  return {
    form,
    submit: () => {
      if (form.value.valid) {
        const values = form.value.value;

        for (const key in values) {
          if (Object.prototype.hasOwnProperty.call(values, key)) {
            const value = values[key];
            const field = props.fields.find((field) => field.key === key);

            if (props.excludeReadonly && field && field.readonly) {
              delete values[key];
            }

            if (props.excludeDisabled && field && field.disabled) {
              delete values[key];
            }

            if (props.excludeUndefined && (value === undefined || value === null)) {
              delete values[key];
            }
          }
        }

        emit("submit", values);
      } else {
        form.value.markAllAsTouched();
      }
    },
  };
};
