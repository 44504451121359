<script lang="ts" setup>
import { ref, onBeforeMount, watch, defineProps, withDefaults } from "vue";
import { useRoute } from "vue-router";
import { useLayout } from "./composables/Layout";
import { SidebarMenuItem } from "../../models/SidebarMenuItem";

const route = useRoute();

const { layoutConfig, layoutState, setActiveMenuItem, onMenuToggle } = useLayout();

const props = withDefaults(
  defineProps<{
    item: SidebarMenuItem;
    index?: number;
    root?: boolean;
    parentItemKey?: string;
  }>(),
  {
    index: 0,
    root: true,
  },
);

const isActiveMenu = ref(false);
const itemKey = ref<string | undefined>();

onBeforeMount(() => {
  itemKey.value = props.parentItemKey ? props.parentItemKey + "-" + props.index : String(props.index);

  const activeItem = layoutConfig.value.activeMenuItem;

  isActiveMenu.value = activeItem === itemKey.value || (activeItem?.startsWith(itemKey.value + "-") ?? false);
});

watch(
  () => layoutConfig.value.activeMenuItem,
  (newVal) => {
    isActiveMenu.value = newVal === itemKey.value || (newVal?.startsWith(itemKey.value + "-") ?? false);
  },
);

const itemClick = (event: MouseEvent, item: SidebarMenuItem) => {
  if (item.disabled) {
    event.preventDefault();
    return;
  }

  const { overlayMenuActive, staticMenuMobileActive } = layoutState.value;

  if ((item.to || item.url) && (staticMenuMobileActive || overlayMenuActive)) {
    onMenuToggle();
  }

  if (item.command) {
    item.command(event, item);
  }

  const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey : itemKey) : itemKey.value;

  setActiveMenuItem(foundItemKey);
};

const checkActiveRoute = (item: SidebarMenuItem) => {
  return item.to ? (typeof item.to === "string" ? route.path === item.to : route.name === item.to.name) : false;
};
</script>

<template>
  <li v-if="item.separator && item.visible !== false" class="menu-separator"></li>

  <li v-else :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }">
    <div v-if="root && item.visible !== false" class="layout-menuitem-root-text">{{ item.label }}</div>

    <!-- Todo: check -->
    <a v-if="(!item.to || item.items) && item.visible !== false" :href="item.url" @click="itemClick($event, item)" :class="item.class" :target="item.target" tabindex="0">
      <i :class="item.icon" class="layout-menuitem-icon"></i>
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
    </a>

    <router-link v-if="item.to && !item.items && item.visible !== false" @click="itemClick($event, item)" :class="[item.class, { 'active-route': checkActiveRoute(item) }]" tabindex="0" :to="item.to">
      <i :class="item.icon" class="layout-menuitem-icon"></i>
      <span class="layout-menuitem-text">{{ item.label }}</span>
      <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
    </router-link>

    <Transition v-if="item.items && item.visible !== false" name="layout-submenu">
      <ul v-show="root ? true : isActiveMenu" class="layout-submenu">
        <menu-item v-for="(child, i) in item.items" :key="i" :index="i" :item="child" :parentItemKey="itemKey" :root="false"></menu-item>
      </ul>
    </Transition>
  </li>
</template>
