import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, isRef as _isRef, normalizeStyle as _normalizeStyle, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "m-auto font-medium text-4xl line-height-2" }

import { useModal } from "../composables/useModal";
import severityToColor from "../../../consts/severityToColor";

// Tod: add event to template en intranet4

export default /*@__PURE__*/_defineComponent({
  __name: 'Modal',
  setup(__props) {

const { component, props, severity, header, openPosition, maximizable, visible, width, onclose, events, closable } = useModal();

return (_ctx: any,_cache: any) => {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _unref(visible),
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_isRef(visible) ? (visible).value = $event : null)),
    position: _unref(openPosition),
    maximizable: _unref(maximizable),
    closable: _unref(closable),
    style: _normalizeStyle({ width: _unref(width) }),
    modal: true,
    class: "modal",
    onAfterHide: _unref(onclose)
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(`flex modal-header w-full bg-${_unref(severityToColor)[_unref(severity)]}`)
      }, [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_unref(header)), 1)
      ], 2)
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(component)), _mergeProps(_unref(props), _toHandlers(_unref(events))), null, 16))
    ]),
    _: 1
  }, 8, ["visible", "position", "maximizable", "closable", "style", "onAfterHide"]))
}
}

})