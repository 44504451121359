<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from "vue";
import { DynamicFormField } from "../interfaces/DynamicFormField";
import { useDynamicForm } from "../composables/useDynamicForm";
import DynamicInput from "../../DynamicInput/components/DynamicInput.vue";

// Todo: add documentation
const props = withDefaults(
  defineProps<{
    fields: DynamicFormField<unknown>[];
    submittable?: boolean;
    submitText?: string;
    submitIcon?: string;
    cancelable?: boolean;
    cancelText?: string;
    cancelIcon?: string;
    mdCol?: number;
    lgCol?: number;
    xlCol?: number;
    excludeUndefined?: boolean;
    excludeReadonly?: boolean;
    excludeDisabled?: boolean;
    loading?: boolean;
  }>(),
  {
    submittable: true,
    submitText: "Send",
    submitIcon: "pi pi-check",
    cancelable: true,
    cancelText: "Cancel",
    cancelIcon: "pi pi-times",
    mdCol: 12,
    lgCol: 12,
    xlCol: 12,
    excludeUndefined: false,
    excludeReadonly: false,
    excludeDisabled: false,
    loading: false,
  },
);

const emit = defineEmits<{
  /**
   * cancel form
   */
  (e: "cancel"): void;
  /**
   * submit form
   */
  (e: "submit", event: { [key: string]: unknown }): void;
  /**
   * change form
   */
  (e: "change", event: { key: string; value: unknown }): void;
}>();

const { form, submit } = useDynamicForm(props, emit);
</script>

<template>
  <div class="formgrid grid">
    <div :class="`field col md:col-${mdCol ?? 12} lg:col-${lgCol ?? 12} xl:col-${xlCol ?? 12}`" v-for="field in fields" :key="field.key">
      <label>{{ field.label }}</label>
      <DynamicInput
        :readonly="field.readonly"
        :disabled="field.disabled || loading"
        :name="field.key"
        :class="`w-full ${form.fields[field.key].showError ? 'p-invalid' : ''}`"
        v-model="form.fields[field.key].value"
        :type="field.type"
        :placeholder="field.placeholder"
        :text="field.text"
        :int="field.int"
        :float="field.float"
        :date="field.date"
        :datetime="field.datetime"
        :select="field.select"
        :radio="field.radio"
        :checkbox="field.checkbox"
        :currency="field.currency"
        :file="field.file"
        :files="field.files"
        :color="field.color"
        :rating="field.rating"
        :time="field.time"
        :dropdown="field.dropdown"
      ></DynamicInput>

      <template v-if="form.fields[field.key].showError">
        <div v-for="(error, index) in form.fields[field.key].errors" :key="index" class="text-sm text-red-500">{{ error }}</div>
      </template>
    </div>

    <div class="col-12" v-if="loading">
      <ProgressBar mode="indeterminate" style="height: 8px"></ProgressBar>
    </div>

    <div class="col-12 flex" v-else>
      <Button severity="secondary" class="p-ripple ml-2 mr-auto" type="button" :label="cancelText" :icon="cancelIcon" icon-pos="left" v-ripple @click="emit('cancel')" v-if="cancelable"></Button>
      <Button class="p-ripple ml-auto mr-2" type="button" :label="submitText" :icon="submitIcon" icon-pos="left" v-ripple @click="submit" v-if="submittable"></Button>
    </div>
  </div>
</template>

<style scoped></style>
