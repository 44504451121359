import { Component, Prop, ref } from "vue";
import { ModalOpenPosition } from "../enums/ModalOpenPosition";
import { Severities } from "../../../enums/Severities";

const component = ref<Component | undefined>();
const props = ref<Prop<unknown, unknown> | undefined>();
const events = ref<{ [key: string]: unknown } | undefined>();
const severity = ref(Severities.Primary);
const header = ref("");
const openPosition = ref(ModalOpenPosition.Center);
const maximizable = ref(false);
const visible = ref(false);
const width = ref("50vw");
const closable = ref(true);

export const useModal = () => {
  return {
    component,
    props,
    events,
    severity,
    header,
    openPosition,
    maximizable,
    visible,
    width,
    closable,
    onclose: () => {
      component.value = undefined;
      props.value = undefined;
    },
  };
};
