<script lang="ts" setup>
import { ref, computed, Ref } from "vue";
import { useLayout } from "./composables/Layout";
import { MenuItem } from "primevue/menuitem";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useAuthStore } from "../../composables/useAuthStore";
import { useSidebarMenuItems } from "../../composables/useSidebarMenuItems";
import { firstLastname, firstWord } from "../../utils/helpers";
import { useLayoutModal } from "../../composables/useLayoutModal";
import ChangePassword from "../../modules/profile/components/ChangePassword.vue";
import { Severities } from "../../enums/Severities";
import { isBusiness } from "@/router/guards";

const { onMenuToggle, logo, title, profileMenu, ProfileMenuToggle } = useLayout();
const { user, signOut } = useAuthStore();
const { clearSidebarMenuItems } = useSidebarMenuItems();
const toast = useToast();
const router = useRouter();
const { open } = useLayoutModal();

const organizationLogo = computed(() => user.value?.organization?.logo);
// const profileImg = computed(() => user.value?.img);
const profileName = computed(() => `${firstWord(user.value?.name ?? "")} ${firstLastname(user.value?.lastname ?? "")}`.trim());
const profileInitials = computed(() => (user.value ? `${user.value.name.substring(0, 1)}${user.value.lastname.substring(0, 1)}` : "P"));
const topbarMenuActive = ref(false);
// const badge = computed(() => user.value?.notifications ?? 0);

const topbarMenuClasses = computed(() => ({
  "layout-topbar-menu-mobile-active": topbarMenuActive.value,
}));

const ProfileMenuItems: Ref<MenuItem[]> = ref([
  {
    items: [
      {
        label: "Change password",
        icon: "fa-solid fa-key",
        command: () => {
          open(ChangePassword, "Change Password", {}, {}, { severity: Severities.Danger });
        },
      },
      ...(isBusiness()
        ? [
            {
              separator: true,
            },
            {
              label: "My Orders",
              icon: "fa-solid fa-cart-shopping",
              command: () => {
                router.push({ name: "users.reports.orders" });
              },
            },
          ]
        : []),
      {
        separator: true,
      },
      {
        label: "Logout",
        icon: "pi pi-sign-out",
        command: () => {
          signOut(toast, router).then(clearSidebarMenuItems);
        },
      },
    ],
  },
]);
</script>

<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img :src="logo" alt="logo" />
      <span>{{ title }}</span>
    </router-link>

    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle()">
      <i class="pi pi-bars"></i>
    </button>

    <div class="m-auto layout-topbar-logo" v-if="organizationLogo">
      <img :src="organizationLogo" class="organization-logo" alt="logo" />
    </div>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <span class="text-xl font-bold my-auto">{{ user?.location?.name }}</span>

      <button type="button" class="p-link layout-topbar-button-avatar" @click="ProfileMenuToggle">
        <Avatar :label="profileInitials" class="mr-2 border-1 border-primary bg-primary-200 text-white p-overlay-badge" size="large" shape="circle" />
        <span>{{ profileName }}</span>
      </button>
      <Menu ref="profileMenu" id="overlay_menu" :model="ProfileMenuItems" :popup="true"></Menu>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.organization-logo {
  height: 4.5rem !important;
  margin-right: 0 !important;
}
</style>
