import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"


import { useChangePassword } from "../composables/useChangePassword";
import DynamicForm from "@/components/DynamicForm/components/DynamicForm.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePassword',
  props: {
    cancelable: { type: Boolean, default: true }
  },
  emits: ["success"],
  setup(__props: any, { emit: __emit }) {



const emits = __emit;

const { loading, fields, onCancel, onSubmit } = useChangePassword(emits);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(DynamicForm, {
    fields: _unref(fields),
    cancelable: _ctx.cancelable,
    onCancel: _unref(onCancel),
    onSubmit: _unref(onSubmit),
    loading: _unref(loading)
  }, null, 8, ["fields", "cancelable", "onCancel", "onSubmit", "loading"]))
}
}

})