/**
 * Enum of input types
 *
 * @author: Josué Cruz <jicruz@voiceteam.com>
 * @version: 1.0
 * @since: 2023-06-26
 * @copyright: Voiceteam Call
 */
export enum FilterItemTypes {
  text,
  int,
  float,
  boolean,
  date,
  datetime,
  select,
  tristate,
}
