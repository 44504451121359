import { ref } from "vue";
import { DynamicFormField } from "../../../components/DynamicForm";
import { useToast } from "primevue/usetoast";
import { useLayoutModal } from "../../../composables/useLayoutModal";
import { InputTypes } from "../../../components/DynamicInput";
import { Validators } from "../../../consts/Validators";
import { Http } from "../../../services/Http";
import { useAuthStore } from "../../../composables/useAuthStore";
import { useOverlayMessages } from "../../../composables/useOverlayMessages";

export const useChangePassword = (emits: (e: "success") => void) => {
  const toast = useToast();
  const { addToast } = useOverlayMessages();
  const { close } = useLayoutModal();
  const { user } = useAuthStore();

  const http = new Http();
  const loading = ref(false);

  return {
    loading,
    fields: ref<DynamicFormField<unknown>[]>([
      { key: "old", label: "Current Password", type: InputTypes.password, validators: [Validators.required] },
      { key: "password", label: "New Password", type: InputTypes.password, validators: [Validators.required, Validators.min(7)] },
      { key: "repeat", label: "Repeat Password", type: InputTypes.password, validators: [Validators.required, Validators.min(7)] },
    ]),
    onCancel: close,
    onSubmit: async (event: { [key: string]: unknown }) => {
      loading.value = true;

      const { old, password, repeat } = event;

      if (password === repeat) {
        const { data } = await http.put<undefined>(`users/${user.value?.user_id}/password`, { old, password });

        if (data.success) {
          close();

          emits("success");

          addToast(toast, { summary: "Validation", severity: "success", detail: "Your password has been changed." });
        } else {
          addToast(toast, { summary: "Validation", severity: "error", detail: data.errors.message });
        }
      } else {
        addToast(toast, { summary: "Validation", severity: "warn", detail: "Passwords must be match." });
      }

      loading.value = false;
    },
  };
};
