import { RouteRecordRaw } from "vue-router";
import { isAdmin, isBusiness } from "../../../router/guards";

const routes: Array<RouteRecordRaw> = [
  {
    path: "restaurants/:id",
    name: "restaurants",
    component: () => import(/* webpackChunkName: "plate" */ "../views/Restaurant.vue"),
    beforeEnter: [isBusiness],
    props: (route) => ({ organization_id: Array.isArray(route.params.id) ? parseInt(route.params.id[0]) : parseInt(route.params.id) }),
  },
  {
    path: "cost-centers",
    name: "organizations.costCenters",
    component: () => import(/* webpackChunkName: "cost-center" */ "../views/CostCenter.vue"),
    beforeEnter: [isBusiness, isAdmin],
  },
  {
    path: "locations",
    name: "organizations.locations",
    component: () => import(/* webpackChunkName: "location" */ "../views/Locations.vue"),
    beforeEnter: [isBusiness, isAdmin],
  },
  {
    path: "suppliers",
    name: "organizations.suppliers",
    component: () => import(/* webpackChunkName: "suppliers" */ "../views/Suppliers.vue"),
    beforeEnter: [isBusiness, isAdmin],
  },
];

export default routes;
