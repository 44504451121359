<!-- eslint-disable @typescript-eslint/no-explicit-any -->
<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from "vue";
import { useSelectInput } from "../composables/useSelectInput";

declare type SelectInputProp = {
  /**
   * a space-separated list of the case-sensitive classes of the element.
   */
  class?: string;

  /**
   * a boolean which indicates whether the control is disabled.
   */
  disabled?: boolean;

  /**
   * a boolean which indicates if the control has search area.
   */
  filter?: boolean;

  /**
   * Maximum number of selectable items. <= 0 to unlimited
   */
  limit?: number;

  /**
   * Whether to show the header checkbox to toggle the selection of all items at once.
   */
  maxSelectedLabels?: number;

  /**
   * for v-model support
   */
  modelValue?: unknown | unknown[];

  /**
   * name of element
   */
  name?: string;

  /**
   * Property name or getter function to use as the label of an option.
   */
  optionLabel?: string | ((data: unknown) => string);

  /**
   * An array of items to display as the available options.
   */
  options?: { [key: string]: any }[];

  /**
   * Property name or getter function to use as the value of an option, defaults to the option itself when not defined.
   */
  optionValue?: string | ((data: unknown) => unknown);

  /**
   * defines the text displayed in a form control when the control has no value.
   */
  placeholder?: string;

  /**
   * when present, makes the element not mutable, meaning the user can not edit the control
   */
  readonly?: boolean;

  /**
   * load options items from get url
   */
  url?: string;
};

const props = withDefaults(defineProps<SelectInputProp>(), {
  readonly: false,
  disabled: false,
  filter: true,
  limit: 1,
});

const emit = defineEmits(["update:modelValue"]);

const { select, loading, selectOptions, value, hide } = useSelectInput(props, emit);
</script>

<template>
  <MultiSelect
    ref="select"
    v-model="value"
    :placeholder="placeholder"
    :class="props.class"
    :disabled="disabled"
    :readonly="readonly"
    :name="name"
    :options="selectOptions"
    :optionLabel="optionLabel ?? 'label'"
    :optionValue="optionValue ?? 'key'"
    :selectionLimit="limit ?? 1"
    :loading="loading"
    :maxSelectedLabels="maxSelectedLabels"
    :filter="filter"
    @change="hide($event)"
  ></MultiSelect>
</template>
