/**
 * Enum of input types
 *
 * @author: Josué Cruz <jicruz@voiceteam.com>
 * @version: 1.0
 * @since: 2023-06-26
 * @copyright: Voiceteam Call
 */
export enum FilterMethods {
  equal = "$$$",
  isNotEqual = "!$$$",
  startWith = "**$$$",
  endWith = "$$$**",
  contains = "**$$$**",
  between = "$$$||%%%",
  greaterThan = ">$$$",
  lowerThan = "<$$$",
  greaterOrEqualThan = "~>$$$",
  lowerOrEqualThan = "~<$$$",
  isOneOf = "($$$)",
  isNotOneOf = "!($$$)",
}
