/* eslint-disable @typescript-eslint/no-explicit-any */
import { FilterItem } from "../interfaces/FilterItem";
import { ref } from "vue";
import { FilterMethods } from "../enums/FilterMethods";
import { FilterItemTypes } from "../enums/FilterItemTypes";
import { Filter } from "../interfaces/Filter";
import { Validators } from "../../../consts/Validators";
import { useFormGroup } from "../../../composables/useFormGroup";
import { Severities } from "../../../enums/Severities";

export const useFormFilter = (
  props: Readonly<{
    fields: FilterItem[];
    severity?: Severities;
  }>,
  emit: (e: "apply", value: Filter) => void,
) => {
  const selected = ref<FilterItem | undefined>();
  const between = ref(false);
  const limit = ref(1);

  const form = useFormGroup({
    field: { validators: [Validators.required] },
    method: { validators: [Validators.required] },
    term: { validators: [Validators.required] },
    max: {
      validators: [
        (item) => {
          return between.value ? Validators.required(item) : undefined;
        },
      ],
    },
  });

  form.value.get("method")?.subscribe((item) => {
    between.value = item === FilterMethods.between;

    limit.value = item === FilterMethods.isNotOneOf || item === FilterMethods.isOneOf ? -1 : 1;
  });

  form.value.get("field")?.subscribe((field) => {
    if (field && Array.isArray(field) && field.length) {
      const key = field[0];
      selected.value = props.fields.find((field: FilterItem) => field.key === key);
    } else {
      selected.value = undefined;
    }

    if (selected.value?.type === FilterItemTypes.boolean) {
      form.value.fields.term.updateValue(false);
      form.value.fields.max.updateValue(false);
    } else if (selected.value?.type === FilterItemTypes.tristate) {
      form.value.fields.term.updateValue(null);
      form.value.fields.max.updateValue(null);
    } else {
      form.value.fields.term.updateValue(undefined);
      form.value.fields.max.updateValue(undefined);
    }
  });

  return {
    selected,
    between,
    form,
    limit,
    submit: () => {
      if (form.value.valid && selected.value) {
        const { method, term, max } = form.value.fields;

        const key = selected.value.key;
        const label = selected.value.label;

        const value = method.value === FilterMethods.between ? [term.value, max.value] : term.value;

        emit("apply", { key, label, type: selected.value.type, method: method.value, value, options: selected.value.options ? [...selected.value.options] : undefined, keyField: selected.value.keyField, labelField: selected.value.labelField });

        form.value.reset();
      } else {
        form.value.markAllAsTouched();
      }
    },
  };
};
