import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["accept", "multiple", "name"]
const _hoisted_2 = { class: "flex flex-wrap justify-content-between align-items-center flex-1" }
const _hoisted_3 = {
  key: 0,
  class: "grid"
}
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = {
  key: 1,
  class: "fa-solid fa-file-code text-orange-500",
  style: {"font-size":"64px"}
}
const _hoisted_6 = {
  key: 2,
  class: "fa-solid fa-file-audio text-blue-500",
  style: {"font-size":"64px"}
}
const _hoisted_7 = {
  key: 3,
  class: "fa-solid fa-file-video text-500",
  style: {"font-size":"64px"}
}
const _hoisted_8 = {
  key: 4,
  class: "fa-solid fa-file-pdf text-red-500",
  style: {"font-size":"64px"}
}
const _hoisted_9 = {
  key: 5,
  class: "fa-solid fa-file-lines text-600",
  style: {"font-size":"64px"}
}
const _hoisted_10 = {
  key: 6,
  class: "fa-solid fa-file-code text-primary",
  style: {"font-size":"64px"}
}
const _hoisted_11 = {
  key: 7,
  class: "fa-solid fa-file-zipper text-yellow-500",
  style: {"font-size":"64px"}
}
const _hoisted_12 = {
  key: 8,
  class: "fa-solid fa-file-zipper text-red-400",
  style: {"font-size":"64px"}
}
const _hoisted_13 = {
  key: 9,
  class: "fa-solid fa-file-zipper text-700",
  style: {"font-size":"64px"}
}
const _hoisted_14 = {
  key: 10,
  class: "fa-solid fa-file-csv text-green-600",
  style: {"font-size":"64px"}
}
const _hoisted_15 = {
  key: 11,
  class: "fa-solid fa-file-excel text-green-600",
  style: {"font-size":"64px"}
}
const _hoisted_16 = {
  key: 12,
  class: "fa-solid fa-file-excel text-green-600",
  style: {"font-size":"64px"}
}
const _hoisted_17 = {
  key: 13,
  class: "fa-solid fa-file-word text-blue-400",
  style: {"font-size":"64px"}
}
const _hoisted_18 = {
  key: 14,
  class: "fa-solid fa-file-word text-blue-400",
  style: {"font-size":"64px"}
}
const _hoisted_19 = {
  key: 15,
  class: "fa-solid fa-file-powerpoint text-red-300",
  style: {"font-size":"64px"}
}
const _hoisted_20 = {
  key: 16,
  class: "fa-solid fa-file-powerpoint text-red-300",
  style: {"font-size":"64px"}
}
const _hoisted_21 = {
  key: 17,
  class: "fa-solid fa-file text-primary",
  style: {"font-size":"64px"}
}
const _hoisted_22 = { class: "mx-2" }
const _hoisted_23 = {
  class: "font-semibold block max-w-24rem",
  style: {"word-wrap":"break-word"}
}
const _hoisted_24 = { class: "size-render" }
const _hoisted_25 = {
  key: 1,
  class: "flex align-items-center justify-content-center flex-column"
}


import { useFileInput } from "../composables/useFileInput";

declare type FileInputProp = {
  /**
   * takes as its value a comma-separated list of one or more file types, or unique file type specifiers, describing which file types to allow. Like accept in &lt;input type=&quot;file&quot; /&gt;
   */
  accept?: string;

  /**
   * a space-separated list of the case-sensitive classes of the element.
   */
  class?: string;

  /**
   * a boolean which indicates whether the control is disabled.
   */
  disabled?: boolean;

  /**
   * for v-model support
   */
  modelValue?: File | File[];

  /**
   * a boolean which indicates the file input allows the user to select more than one file
   */
  multiple?: boolean;

  /**
   * name of element
   */
  name?: string;

  /**
   * defines the text displayed in a form control when the control has no value.
   */
  placeholder?: string;

  /**
   * when present, makes the element not mutable, meaning the user can not edit the control
   */
  readonly?: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FileInput',
  props: {
    accept: { default: "" },
    class: {},
    disabled: { type: Boolean, default: false },
    modelValue: {},
    multiple: { type: Boolean, default: false },
    name: {},
    placeholder: {},
    readonly: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { input, highlight, files, formatSize, objectURL, change, removeFile, dropFile, dragEnter, dragLeave } = useFileInput(props, emit);

return (_ctx: any,_cache: any) => {
  const _component_Button = _resolveComponent("Button")!
  const _component_Panel = _resolveComponent("Panel")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("input", {
      ref_key: "input",
      ref: input,
      type: "file",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(change) && _unref(change)(...args))),
      style: {"display":"none"},
      accept: _ctx.accept,
      multiple: _ctx.multiple,
      name: _ctx.name
    }, null, 40, _hoisted_1),
    _createVNode(_component_Panel, {
      class: _normalizeClass(`${_ctx.readonly || _ctx.disabled ? 'readonly' : ''} ${props.class}`)
    }, {
      header: _withCtx(() => [
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives(_createVNode(_component_Button, {
            class: "btn-file",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(input)?.click())),
            icon: "pi pi-file",
            rounded: "",
            outlined: "",
            disabled: _ctx.readonly || _ctx.disabled
          }, null, 8, ["disabled"]), [
            [_directive_tooltip, 'Change Selected Files']
          ]),
          _withDirectives(_createVNode(_component_Button, {
            class: "btn-clear",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('update:modelValue', undefined))),
            icon: "pi pi-trash",
            severity: "danger",
            rounded: "",
            outlined: "",
            disabled: _ctx.readonly || _ctx.disabled
          }, null, 8, ["disabled"]), [
            [
              _directive_tooltip,
              'Remove All Selected Files',
              void 0,
              { left: true }
            ]
          ])
        ])), [
          [
            _directive_tooltip,
            _ctx.placeholder,
            void 0,
            { top: true }
          ]
        ])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createElementBlock("div", {
          onDragenter: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(dragEnter) && _unref(dragEnter)(...args))),
          onDragleave: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(dragLeave) && _unref(dragLeave)(...args))),
          onDragover: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_unref(dragEnter) && _unref(dragEnter)(...args))),
          onDrop: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_unref(dropFile) && _unref(dropFile)(...args))),
          class: _normalizeClass(["draggable p-2 max-h-15rem overflow-x-hidden overflow-y-auto", { 'border-2 border-primary border-round-sm border-dashed': _unref(highlight) }])
        }, [
          (_unref(files).length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(files), (file, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: file.name + file.type + file.size,
                    class: "file-row m-0 px-1 py-2 col-6 flex border-1 surface-border align-items-center"
                  }, [
                    (file.type.startsWith('image'))
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          role: "presentation",
                          alt: file.name,
                          src: _unref(objectURL)(file),
                          height: "64",
                          class: "shadow-2"
                        }, null, 8, _hoisted_4))
                      : (file.type === 'text/html')
                        ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                        : (file.type.startsWith('audio'))
                          ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                          : (file.type.startsWith('video'))
                            ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                            : (file.type === 'application/pdf')
                              ? (_openBlock(), _createElementBlock("i", _hoisted_8))
                              : (file.type === 'application/rtf')
                                ? (_openBlock(), _createElementBlock("i", _hoisted_9))
                                : (file.type === 'application/xml')
                                  ? (_openBlock(), _createElementBlock("i", _hoisted_10))
                                  : (file.type === 'application/zip')
                                    ? (_openBlock(), _createElementBlock("i", _hoisted_11))
                                    : (file.type === 'application/vnd.rar')
                                      ? (_openBlock(), _createElementBlock("i", _hoisted_12))
                                      : (file.type === 'application/x-7z-compressed')
                                        ? (_openBlock(), _createElementBlock("i", _hoisted_13))
                                        : (file.type === 'text/csv')
                                          ? (_openBlock(), _createElementBlock("i", _hoisted_14))
                                          : (file.type === 'application/vnd.ms-excel')
                                            ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                                            : (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
                                              ? (_openBlock(), _createElementBlock("i", _hoisted_16))
                                              : (file.type === 'application/msword')
                                                ? (_openBlock(), _createElementBlock("i", _hoisted_17))
                                                : (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                                                  ? (_openBlock(), _createElementBlock("i", _hoisted_18))
                                                  : (file.type === 'application/vnd.ms-powerpoint')
                                                    ? (_openBlock(), _createElementBlock("i", _hoisted_19))
                                                    : (file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
                                                      ? (_openBlock(), _createElementBlock("i", _hoisted_20))
                                                      : (_openBlock(), _createElementBlock("i", _hoisted_21)),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("span", _hoisted_23, _toDisplayString(file.name), 1),
                      _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(formatSize)(file.size)), 1)
                    ]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-times",
                      onClick: ($event: any) => (_unref(removeFile)(index)),
                      rounded: "",
                      text: "",
                      severity: "danger",
                      size: "small",
                      class: "ml-auto mr-1 mt-0 mb-auto",
                      disabled: _ctx.readonly || _ctx.disabled
                    }, null, 8, ["onClick", "disabled"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_25, _cache[7] || (_cache[7] = [
                _createElementVNode("i", { class: "pi pi-cloud-upload border-2 border-circle p-5 text-6xl text-400 border-400" }, null, -1),
                _createElementVNode("p", { class: "mt-4 mb-0" }, "Drag and drop files to here to upload.", -1)
              ])))
        ], 34)), [
          [
            _directive_tooltip,
            _ctx.placeholder,
            void 0,
            { bottom: true }
          ]
        ])
      ]),
      _: 1
    }, 8, ["class"])
  ], 64))
}
}

})