/* eslint-disable @typescript-eslint/no-explicit-any */
import { min as minValidation, max as maxValidation, requiredValue } from "../utils/helpers";

export declare type ValidatorFn<T> = (item?: T) => string | undefined;
export declare type CreateValidatorFn<E, T> = (config?: E) => ValidatorFn<T>;

export const Validators = {
  required: (value: unknown) => (requiredValue(value, () => true) ? undefined : "This value is required"),
  min:
    (min: number): ValidatorFn<string | number | File | unknown[]> =>
    (value) =>
      minValidation(min, value) ? undefined : `The min value must be ${min}`,
  max:
    (max: number): ValidatorFn<string | number | File | unknown[]> =>
    (value) =>
      maxValidation(max, value) ? undefined : `The max value must be ${max}`,
};
