import { ref } from "vue";
import { FormControl } from "../classes/FormControl";
import { ValidatorFn } from "../consts/Validators";
import { FormGroup } from "../classes/FormGroup";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export declare type useFormGroupField = { [key: string]: { value?: any; validators?: ValidatorFn<any>[]; touch?: boolean } };
export const useFormGroup = (fields: useFormGroupField) => {
  const form: { [key: string]: FormControl<unknown> } = {};

  for (const key in fields) {
    if (Object.prototype.hasOwnProperty.call(fields, key)) {
      const { value, validators, touch } = fields[key];

      form[key] = new FormControl({ value, validators, touch });
    }
  }

  return ref(new FormGroup(form));
};
