import { MutationTree } from "vuex";
import { CartState, CartItem } from "./state";

const mutation: MutationTree<CartState> = {
  push(state, plate: CartItem) {
    state.items = [...state.items, { ...plate, index: state.items.length }];
  },

  update(state, plate: CartItem) {
    state.items[plate.index ?? 0] = plate;
  },

  remove(state, plate: CartItem) {
    state.items = state.items.filter((item) => item.index !== plate.index).map((item, index) => ({ ...item, index }));
  },

  clear(state) {
    state.items = [];
  },

  startLoading(state) {
    state.loading = true;
  },

  endLoading(state) {
    state.loading = false;
  },
};

export default mutation;
