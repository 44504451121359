import { computed } from "vue";
import { DynamicInputProps } from "../interfaces/DynamicInputProps";
import { InputTypes } from "../enums/InputTypes";

export const useDynamicInput = (props: Readonly<DynamicInputProps>, emit: (e: "update:modelValue", value: File | File[] | undefined) => void) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const config = computed<{ [key: string]: any }>(() => {
    switch (props.type) {
      case InputTypes.text:
        return {
          type: props.text?.type ?? "text",
        };
      case InputTypes.int:
        return {
          min: props.int?.min,
          max: props.int?.max,
          buttons: props.int?.buttons ?? true,
          step: props.int?.step,
        };
      case InputTypes.float:
        return {
          minFractionDigits: props.float?.minFractionDigits ?? 2,
          maxFractionDigits: props.float?.maxFractionDigits ?? props.float?.minFractionDigits ?? 2,
          min: props.float?.min,
          max: props.float?.max,
          buttons: props.float?.buttons ?? true,
          step: props.float?.step,
        };
      case InputTypes.date:
        return {
          format: props.date?.format,
          range: props.date?.range ? "range" : "single",
          min: props.date?.min,
          max: props.date?.max,
        };
      case InputTypes.datetime:
        return {
          format: props.datetime?.format,
          range: props.datetime?.range ? "range" : "single",
          min: props.datetime?.min,
          max: props.datetime?.max,
        };
      case InputTypes.select:
        return {
          options: props.select?.options,
          url: props.select?.url,
          optionValue: props.select?.optionValue ?? "key",
          optionLabel: props.select?.optionLabel ?? "label",
          limit: props.select?.limit ?? 1,
          maxSelectedLabels: props.select?.maxSelectedLabels ?? 3,
          filter: props.select?.filter ?? true,
        };
      case InputTypes.radio:
        return {
          options: props.radio?.options ?? [],
          optionValue: props.radio?.optionValue ?? "key",
          optionLabel: props.radio?.optionLabel ?? "label",
        };
      case InputTypes.checkbox:
        return {
          options: props.checkbox?.options ?? [],
          optionValue: props.checkbox?.optionValue ?? "key",
          optionLabel: props.checkbox?.optionLabel ?? "label",
        };
      case InputTypes.currency:
        return {
          min: props.currency?.min,
          max: props.currency?.max,
          currency: props.currency?.currency ?? "USD",
          locale: props.currency?.locale ?? "en-US",
        };
      case InputTypes.file:
        return {
          accept: props.file?.accept,
        };
      case InputTypes.files:
        return {
          accept: props.files?.accept,
        };
      case InputTypes.color:
        return {
          format: props.color?.format ?? "hex",
        };
      case InputTypes.rating:
        return {
          stars: props.rating?.stars ?? 10,
        };
      case InputTypes.time:
        return {
          min: props.time?.min,
          max: props.time?.max,
          format: props.time?.format ?? "12",
        };
      case InputTypes.dropdown:
        return {
          options: props.dropdown?.options ?? [],
          optionValue: props.dropdown?.optionValue ?? "key",
          optionLabel: props.dropdown?.optionLabel ?? "label",
        };
      default:
        return {};
    }
  });

  return {
    value: computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    }),
    config,
  };
};
