import { FilterMethods } from "../enums/FilterMethods";

const filterMethodsNames: { [key: string]: string } = {};

filterMethodsNames[FilterMethods.equal] = "is equal to";
filterMethodsNames[FilterMethods.isNotEqual] = "is not equal to";
filterMethodsNames[FilterMethods.startWith] = "start with";
filterMethodsNames[FilterMethods.endWith] = "end with";
filterMethodsNames[FilterMethods.contains] = "contains";
filterMethodsNames[FilterMethods.between] = "between";
filterMethodsNames[FilterMethods.greaterThan] = "is greater than";
filterMethodsNames[FilterMethods.lowerThan] = "is lower than";
filterMethodsNames[FilterMethods.greaterOrEqualThan] = "is greater or equal than";
filterMethodsNames[FilterMethods.lowerOrEqualThan] = "is lower or equal than";
filterMethodsNames[FilterMethods.isOneOf] = "is one of";
filterMethodsNames[FilterMethods.isNotOneOf] = "is not one of";

export default filterMethodsNames;
