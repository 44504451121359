import { ConfirmationOptions } from "primevue/confirmationoptions";
import { ToastMessageOptions } from "primevue/toast";
import { ToastServiceMethods } from "primevue/toastservice";

export const useOverlayMessages = () => {
  const DEFAULT_TOAST_POSITION = "bottom-right";

  return {
    addToast: (toast: ToastServiceMethods, options: ToastMessageOptions, position?: "top-left" | "top-center" | "top-right" | "bottom-left" | "bottom-center" | "bottom-right" | "center") => {
      toast.add({ life: 10000, ...options, group: position ?? DEFAULT_TOAST_POSITION });
    },
    addConfirmDialog: (confirm: { require: (option: ConfirmationOptions) => unknown; close: () => void }, options: ConfirmationOptions) => {
      return confirm.require({ ...options, group: "dialog" });
    },
  };
};
