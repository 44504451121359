<script setup lang="ts">
import { onMounted } from "vue";
import { useAuthStore } from "./composables/useAuthStore";
import { useRouter } from "vue-router";
import { useLayout } from "./layouts/main/composables/Layout";

const { checkAuth, checking } = useAuthStore();
const { logo, bgImg } = useLayout();
const router = useRouter();

onMounted(() => {
  checkAuth(router);
});
</script>

<template>
  <ConfirmDialog group="dialog"></ConfirmDialog>
  <Toast position="top-left" group="top-left"></Toast>
  <Toast position="top-center" group="top-center"></Toast>
  <Toast position="top-right" group="top-right"></Toast>
  <Toast position="bottom-left" group="bottom-left"></Toast>
  <Toast position="bottom-center" group="bottom-center"></Toast>
  <Toast position="bottom-right" group="bottom-right"></Toast>
  <Toast position="center" group="center"></Toast>

  <!-- Todo: better this -->
  <div class="w-screen h-screen flex flex-column" :style="{ backgroundImage: `url('${bgImg}')` }" v-if="checking">
    <img :src="logo" alt="logo" class="mx-auto mt-auto mb-2 max-w-screen-80 md:max-w-screen-70 lg:max-w-screen-60 xl:max-w-screen-40" />
    <ProgressSpinner class="mx-auto mt-2 mb-auto w-3rem h-3rem sm:w-4rem sm:h-4rem md:w-5rem md:h-5rem lg:w-6rem lg:h-6rem xl:w-7rem xl:h-7rem" strokeWidth="4" />
  </div>
  <RouterView v-else></RouterView>
</template>

<style lang="scss" scoped></style>
