import { computed, ref } from "vue";
import { FileInputProp } from "../interfaces/FileInputProp";

export const useFileInput = (props: Readonly<FileInputProp>, emit: (e: "update:modelValue", value: File | File[] | undefined) => void) => {
  const input = ref<HTMLElement | undefined>();
  const highlight = ref(false);

  const filesArr = computed<File[]>(() => (props.modelValue ? (Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) : []));

  const exitsFile = (file: File) => {
    const { name, lastModified, size, type } = file;

    for (const item of filesArr.value) {
      if (name === item.name && lastModified === item.lastModified && size === item.size && type === item.type) {
        return true;
      }
    }

    return false;
  };

  const isAcceptable = (file: File) => {
    if (props.accept === undefined) return true;

    const accepts = props.accept.split(",").map((txt) => txt.trim());

    for (const accept of accepts) {
      if (accept === "*") return true;

      const [extension, type] = accept.split("/");

      if (type) {
        if (extension === "*") return true;

        const [fileExtension, fileType] = file.type.split("/");

        if (extension !== fileExtension) continue;

        if (type === "*" || type === fileType) return true;
      } else {
        if (file.name.endsWith(extension)) return true;
      }
    }

    return false;
  };

  return {
    input,
    highlight,
    value: computed({
      get: () => props.modelValue,
      set: (value) => {
        emit("update:modelValue", value);
      },
    }),
    files: filesArr,
    formatSize: (bytes: number) => {
      if (bytes === 0) return "0 B";
      const k = 1024;
      const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    objectURL: (file: File) => {
      return URL.createObjectURL(file);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (event: any) => {
      if (props.readonly || props.disabled) {
        return;
      }

      const files: FileList | undefined = event.target?.files;

      if (files) {
        if (!(props.multiple ?? false)) {
          emit("update:modelValue", files[0]);
        } else {
          emit("update:modelValue", [...files]);
        }
      } else {
        emit("update:modelValue", undefined);
      }
    },
    removeFile: (index: number) => {
      if (props.readonly || props.disabled) {
        return;
      }

      if (props.modelValue) {
        if (Array.isArray(props.modelValue) && props.modelValue.length > 1) {
          emit("update:modelValue", [...props.modelValue.slice(0, index), ...props.modelValue.slice(index + 1)]);

          return;
        }

        emit("update:modelValue", undefined);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dropFile: (event: any) => {
      event.preventDefault();
      event.stopPropagation();

      if (props.readonly || props.disabled) {
        return;
      }

      highlight.value = false;

      const files = event.dataTransfer?.files;

      if (files && files.length) {
        if (props.multiple) {
          const noDuplicateFiles = [...files].filter((file) => isAcceptable(file)).filter((file) => !exitsFile(file));

          emit("update:modelValue", [...filesArr.value, ...noDuplicateFiles]);
        } else {
          const file = files[0];

          if (isAcceptable(file)) {
            emit("update:modelValue", file);
          }
        }
      }
    },
    dragEnter: (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();

      if (props.readonly || props.disabled) {
        return;
      }

      highlight.value = true;
    },
    dragLeave: (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();

      if (props.readonly || props.disabled) {
        return;
      }

      highlight.value = false;
    },
    isAcceptable,
  };
};
