import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "layout-menu" }

import { useSidebarMenuItems } from "../../composables/useSidebarMenuItems";
import MenuItem from "./MenuItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'Menu',
  setup(__props) {

const { getSidebarMenuItems } = useSidebarMenuItems();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getSidebarMenuItems).value, (item, i) => {
      return (_openBlock(), _createBlock(MenuItem, {
        key: item,
        item: item,
        index: i
      }, null, 8, ["item", "index"]))
    }), 128))
  ]))
}
}

})