<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from "vue";
import { Filter } from "../interfaces/Filter";
import { useFilterRender } from "../composables/useFilterRender";
import { Severities } from "../../../enums/Severities";
import severityToColor from "../../../consts/severityToColor";

const props = withDefaults(
  defineProps<{
    filter?: Filter;
    severity?: Severities;
  }>(),
  {
    severity: Severities.Primary,
  },
);

defineEmits<{
  (e: "remove", filter: Filter): void;
}>();

const { propLabel, method, value } = useFilterRender(props);
</script>

<template>
  <Chip :removable="true" class="m-1" @remove="$emit('remove', filter)" v-if="filter">
    <span :class="`font-bold text-${severityToColor[severity]}`">{{ propLabel }}</span>
    <span>&nbsp;{{ method }}&nbsp;</span>
    <span class="font-bold">{{ value }}</span>
  </Chip>
</template>

<style scoped lang="scss"></style>
