import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "layout-topbar" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "m-auto layout-topbar-logo"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-xl font-bold my-auto" }

import { ref, computed, Ref } from "vue";
import { useLayout } from "./composables/Layout";
import { MenuItem } from "primevue/menuitem";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import { useAuthStore } from "../../composables/useAuthStore";
import { useSidebarMenuItems } from "../../composables/useSidebarMenuItems";
import { firstLastname, firstWord } from "../../utils/helpers";
import { useLayoutModal } from "../../composables/useLayoutModal";
import ChangePassword from "../../modules/profile/components/ChangePassword.vue";
import { Severities } from "../../enums/Severities";
import { isBusiness } from "@/router/guards";


export default /*@__PURE__*/_defineComponent({
  __name: 'Topbar',
  setup(__props) {

const { onMenuToggle, logo, title, profileMenu, ProfileMenuToggle } = useLayout();
const { user, signOut } = useAuthStore();
const { clearSidebarMenuItems } = useSidebarMenuItems();
const toast = useToast();
const router = useRouter();
const { open } = useLayoutModal();

const organizationLogo = computed(() => user.value?.organization?.logo);
// const profileImg = computed(() => user.value?.img);
const profileName = computed(() => `${firstWord(user.value?.name ?? "")} ${firstLastname(user.value?.lastname ?? "")}`.trim());
const profileInitials = computed(() => (user.value ? `${user.value.name.substring(0, 1)}${user.value.lastname.substring(0, 1)}` : "P"));
const topbarMenuActive = ref(false);
// const badge = computed(() => user.value?.notifications ?? 0);

const topbarMenuClasses = computed(() => ({
  "layout-topbar-menu-mobile-active": topbarMenuActive.value,
}));

const ProfileMenuItems: Ref<MenuItem[]> = ref([
  {
    items: [
      {
        label: "Change password",
        icon: "fa-solid fa-key",
        command: () => {
          open(ChangePassword, "Change Password", {}, {}, { severity: Severities.Danger });
        },
      },
      ...(isBusiness()
        ? [
            {
              separator: true,
            },
            {
              label: "My Orders",
              icon: "fa-solid fa-cart-shopping",
              command: () => {
                router.push({ name: "users.reports.orders" });
              },
            },
          ]
        : []),
      {
        separator: true,
      },
      {
        label: "Logout",
        icon: "pi pi-sign-out",
        command: () => {
          signOut(toast, router).then(clearSidebarMenuItems);
        },
      },
    ],
  },
]);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: "/",
      class: "layout-topbar-logo"
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _unref(logo),
          alt: "logo"
        }, null, 8, _hoisted_2),
        _createElementVNode("span", null, _toDisplayString(_unref(title)), 1)
      ]),
      _: 1
    }),
    _createElementVNode("button", {
      class: "p-link layout-menu-button layout-topbar-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(onMenuToggle)()))
    }, _cache[2] || (_cache[2] = [
      _createElementVNode("i", { class: "pi pi-bars" }, null, -1)
    ])),
    (organizationLogo.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            src: organizationLogo.value,
            class: "organization-logo",
            alt: "logo"
          }, null, 8, _hoisted_4)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["layout-topbar-menu", topbarMenuClasses.value])
    }, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(user)?.location?.name), 1),
      _createElementVNode("button", {
        type: "button",
        class: "p-link layout-topbar-button-avatar",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_unref(ProfileMenuToggle) && _unref(ProfileMenuToggle)(...args)))
      }, [
        _createVNode(_component_Avatar, {
          label: profileInitials.value,
          class: "mr-2 border-1 border-primary bg-primary-200 text-white p-overlay-badge",
          size: "large",
          shape: "circle"
        }, null, 8, ["label"]),
        _createElementVNode("span", null, _toDisplayString(profileName.value), 1)
      ]),
      _createVNode(_component_Menu, {
        ref_key: "profileMenu",
        ref: profileMenu,
        id: "overlay_menu",
        model: ProfileMenuItems.value,
        popup: true
      }, null, 8, ["model"])
    ], 2)
  ]))
}
}

})