export enum ModalOpenPosition {
  Center = "center",
  Left = "left",
  Right = "right",
  Top = "top",
  Bottom = "bottom",
  TopLeft = "topleft",
  TopRight = "topright",
  BottomLeft = "bottomleft",
  BottomRight = "bottomright",
}
