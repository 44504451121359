import { GetterTree } from "vuex";
import { AuthState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<AuthState, StateInterface> = {
  isSystem(state) {
    return state.user?.system ?? false;
  },
  isAdmin(state) {
    return state.user?.admin ?? false;
  },
  isFinance(state) {
    return state.user?.finance ?? false;
  },
  isSupport(state) {
    return state.user?.support ?? false;
  },
  isLogin(state) {
    return state.user !== undefined;
  },
  isBusiness(state) {
    return state.user?.organization?.type === "B";
  },
  isLoading(state) {
    return state.loading;
  },
};

export default getters;
