import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]

import { onMounted } from "vue";
import { useAuthStore } from "./composables/useAuthStore";
import { useRouter } from "vue-router";
import { useLayout } from "./layouts/main/composables/Layout";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { checkAuth, checking } = useAuthStore();
const { logo, bgImg } = useLayout();
const router = useRouter();

onMounted(() => {
  checkAuth(router);
});

return (_ctx: any,_cache: any) => {
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ConfirmDialog, { group: "dialog" }),
    _createVNode(_component_Toast, {
      position: "top-left",
      group: "top-left"
    }),
    _createVNode(_component_Toast, {
      position: "top-center",
      group: "top-center"
    }),
    _createVNode(_component_Toast, {
      position: "top-right",
      group: "top-right"
    }),
    _createVNode(_component_Toast, {
      position: "bottom-left",
      group: "bottom-left"
    }),
    _createVNode(_component_Toast, {
      position: "bottom-center",
      group: "bottom-center"
    }),
    _createVNode(_component_Toast, {
      position: "bottom-right",
      group: "bottom-right"
    }),
    _createVNode(_component_Toast, {
      position: "center",
      group: "center"
    }),
    (_unref(checking))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "w-screen h-screen flex flex-column",
          style: _normalizeStyle({ backgroundImage: `url('${_unref(bgImg)}')` })
        }, [
          _createElementVNode("img", {
            src: _unref(logo),
            alt: "logo",
            class: "mx-auto mt-auto mb-2 max-w-screen-80 md:max-w-screen-70 lg:max-w-screen-60 xl:max-w-screen-40"
          }, null, 8, _hoisted_1),
          _createVNode(_component_ProgressSpinner, {
            class: "mx-auto mt-2 mb-auto w-3rem h-3rem sm:w-4rem sm:h-4rem md:w-5rem md:h-5rem lg:w-6rem lg:h-6rem xl:w-7rem xl:h-7rem",
            strokeWidth: "4"
          })
        ], 4))
      : (_openBlock(), _createBlock(_component_RouterView, { key: 1 }))
  ], 64))
}
}

})