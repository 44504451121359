import { FilterItem } from "../interfaces/FilterItem";
import { Ref, computed, ref } from "vue";
import { Filter } from "../interfaces/Filter";
import OverlayPanel from "primevue/overlaypanel";
import filterItemTypesToFilterMethods from "../consts/filterItemTypesToFilterMethods";
import { Severities } from "../../../enums/Severities";

const appliesArr: { [key: string]: Ref<boolean> } = {};
const filtersArr: { [key: string]: Ref<Filter[]> } = {};

export const useFilter = (key: string, props: { items: FilterItem[]; severity?: Severities }, emit: (e: "search", filters: Filter[]) => void) => {
  if (!Object.prototype.hasOwnProperty.call(appliesArr, key)) appliesArr[key] = ref(true);
  if (!Object.prototype.hasOwnProperty.call(filtersArr, key)) filtersArr[key] = ref<Filter[]>([]);

  const apply = appliesArr[key];
  const filters = filtersArr[key];
  const op = ref<OverlayPanel | undefined>();

  const applyFilter = (filter: Filter) => {
    filters.value = [...(filters.value ?? []), { ...filter, identifier: new Date().getTime() }];

    apply.value = false;
  };

  const search = () => {
    emit("search", [...(filters.value ?? [])]);

    apply.value = true;
  };

  for (const item of props.items) {
    if (item.initial) {
      applyFilter({
        key: item.key,
        label: item.label,
        type: item.type,
        options: item.options ? [...item.options] : undefined,
        keyField: item.keyField,
        labelField: item.labelField,
        value: item.initial.value,
        method: item.initial.method,
      });
    }
  }

  return {
    apply,
    filters,
    op,
    elements: computed(() =>
      props.items.map(
        (item): FilterItem => ({
          ...item,
          methods: item.methods ?? filterItemTypesToFilterMethods[item.type],
        }),
      ),
    ),
    applyFilter,
    removeFilter: (filter: Filter) => {
      const index = filters.value.findIndex((item) => item.identifier === filter.identifier);

      if (index < 0) return;

      filters.value = [...filters.value.slice(0, index), ...filters.value.slice(index + 1)];

      apply.value = false;
    },
    search,
    toggle: (event: Event) => {
      op.value?.toggle(event);
    },
  };
};
