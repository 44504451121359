<script setup lang="ts">
import { defineProps, withDefaults, defineEmits } from "vue";
import { FilterItem } from "../interfaces/FilterItem";
import filterMethodsNames from "../consts/filterMethodsNames";
import filterItemTypeToInputType from "../consts/filterItemTypeToInputType";
import DynamicInput from "../../DynamicInput/components/DynamicInput.vue";
import { Filter } from "../interfaces/Filter";
import { useFormFilter } from "../composables/useFormFilter";
import SelectInput from "../../SelectInput/components/SelectInput.vue";
import { Severities } from "../../../enums/Severities";

const props = withDefaults(
  defineProps<{
    fields: FilterItem[];
    severity?: Severities;
  }>(),
  {
    severity: Severities.Primary,
  },
);

const emit = defineEmits<{
  (e: "apply", value: Filter): void;
}>();

const { selected, between, form, submit, limit } = useFormFilter(props, emit);
</script>

<template>
  <form @submit.prevent="submit" class="grid">
    <div class="col-12">
      <label for="Field: *"></label>

      <SelectInput v-model="form.fields.field.value" :class="`w-full ${form.fields.field.showError ? 'p-invalid' : ''}`" placeholder="Please select" optionLabel="label" optionValue="key" :limit="1" :options="fields"></SelectInput>
    </div>

    <div class="col-12" v-if="selected">
      <label for="Method: *"></label>
      <div class="p-field-radiobutton py-2" v-for="method of selected.methods" :key="method">
        <RadioButton :class="form.fields.method.showError ? 'p-invalid' : ''" v-model="form.fields.method.value" name="method" :value="method"></RadioButton>
        <label :class="`mx-1 ${form.fields.method.showError ? 'text-red-500' : ''}`">{{ filterMethodsNames[method] }}</label>
      </div>

      <template v-if="form.fields.method.showError">
        <div v-for="(error, index) in form.fields.method.errors" :key="index" class="text-sm text-red-500">{{ error }}</div>
      </template>
    </div>

    <div :class="'col-' + (between ? '6 pr-1' : '12 pr-3')" v-if="selected">
      <label :for="between ? 'From:' : 'Value:'"></label>

      <DynamicInput
        :class="`w-full ${form.fields.term.showError ? 'p-invalid' : ''}`"
        v-model="form.fields.term.value"
        :type="filterItemTypeToInputType[selected.type]"
        :date="{ range: selected.range }"
        :datetime="{ range: selected.range }"
        :placeholder="selected.placeholder"
        :select="{ options: selected.options, optionValue: selected.keyField, optionLabel: selected.labelField, limit }"
      ></DynamicInput>

      <template v-if="form.fields.term.showError">
        <div v-for="(error, index) in form.fields.term.errors" :key="index" class="text-sm text-red-500">{{ error }}</div>
      </template>
    </div>

    <div class="col-6 pl-1" v-if="selected && between">
      <label for="To: *"></label>

      <DynamicInput
        :class="`w-full ${form.fields.max.showError ? 'p-invalid' : ''}`"
        v-model="form.fields.max.value"
        :type="filterItemTypeToInputType[selected.type]"
        :date="{ range: selected.range }"
        :datetime="{ range: selected.range }"
        :placeholder="selected.placeholder"
        :select="{ options: selected.options, optionValue: selected.keyField, optionLabel: selected.labelField }"
      ></DynamicInput>

      <template v-if="form.fields.max.showError">
        <div v-for="(error, index) in form.fields.max.errors" :key="index" class="text-sm text-red-500">{{ error }}</div>
      </template>
    </div>

    <div class="col-12" v-if="selected">
      <Button :class="`w-full p-ripple p-button-${severity}`" type="submit" label="Add filter" icon="pi pi-plus" iconPos="left" v-ripple></Button>
    </div>
  </form>
</template>

<style lang="scss">
.p-inputtext.p-component.p-inputnumber-input {
  width: calc(100% - 42px);
}
</style>
