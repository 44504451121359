/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl } from "./FormControl";

/**
 * Enum of input types
 *
 * @author: Josué Cruz <jicruz@voiceteam.com>
 * @description: The FormGroup class is responsible for managing a group of FormControl instances. It provides methods to add and remove form controls, mark all controls as touched, and reset the values of all controls. It also has properties to get the values and validation status of all controls in the group.
 * @version: 1.0
 * @since: 2023-06-26
 * @copyright: Voiceteam Call
 */
export class FormGroup {
  private _fields: { [key: string]: FormControl<any> } = {};
  private _unsubscribes: { [key: string]: () => void } = {};

  public get fields() {
    return { ...this._fields };
  }

  public get value() {
    const value: { [key: string]: any | undefined } = {};

    for (const key in this._fields) {
      if (Object.prototype.hasOwnProperty.call(this._fields, key)) {
        value[key] = this._fields[key].value;
      }
    }

    return value;
  }

  public get valid() {
    for (const key in this._fields) {
      if (Object.prototype.hasOwnProperty.call(this._fields, key)) {
        if (!this._fields[key].validate()) {
          return false;
        }
      }
    }

    return true;
  }

  public constructor(fields: { [key: string]: FormControl<any> }) {
    this._fields = fields;
  }

  public addField(key: string, control: FormControl<any>) {
    this._fields[key] = control;
  }

  public removeField(key: string) {
    Reflect.deleteProperty(this._fields, key);
  }

  public get(key: string) {
    if (Object.prototype.hasOwnProperty.call(this._fields, key)) {
      return this._fields[key];
    }
  }

  public markAllAsTouched() {
    for (const key in this._fields) {
      if (Object.prototype.hasOwnProperty.call(this._fields, key)) {
        this._fields[key].touch();
      }
    }
  }

  public reset() {
    for (const key in this._fields) {
      if (Object.prototype.hasOwnProperty.call(this._fields, key)) {
        this._fields[key].reset();
      }
    }
  }

  public subscribe(fn: (field: string, value?: any) => void) {
    for (const key in this._fields) {
      if (Object.prototype.hasOwnProperty.call(this._fields, key)) {
        if (Object.prototype.hasOwnProperty.call(this._unsubscribes, key)) {
          this._unsubscribes[key]();
        }

        this._unsubscribes[key] = this._fields[key].subscribe((value) => fn(key, value));
      }
    }
  }

  public unsubscribe() {
    for (const key in this._unsubscribes) {
      if (Object.prototype.hasOwnProperty.call(this._unsubscribes, key)) {
        this._unsubscribes[key]();
      }
    }
  }
}
