<script setup lang="ts">
import { defineProps, defineEmits, withDefaults } from "vue";
import { useChangePassword } from "../composables/useChangePassword";
import DynamicForm from "@/components/DynamicForm/components/DynamicForm.vue";

withDefaults(defineProps<{ cancelable: boolean }>(), { cancelable: true });

const emits = defineEmits<{
  /**
   * submit form
   */
  (e: "success"): void;
}>();

const { loading, fields, onCancel, onSubmit } = useChangePassword(emits);
</script>

<template>
  <DynamicForm :fields="fields" :cancelable="cancelable" @cancel="onCancel" @submit="onSubmit" :loading="loading" />
</template>

<style scoped lang="scss"></style>
