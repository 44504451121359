import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 2,
  class: "col-6 pl-1"
}
const _hoisted_5 = {
  key: 3,
  class: "col-12"
}


import { FilterItem } from "../interfaces/FilterItem";
import filterMethodsNames from "../consts/filterMethodsNames";
import filterItemTypeToInputType from "../consts/filterItemTypeToInputType";
import DynamicInput from "../../DynamicInput/components/DynamicInput.vue";
import { Filter } from "../interfaces/Filter";
import { useFormFilter } from "../composables/useFormFilter";
import SelectInput from "../../SelectInput/components/SelectInput.vue";
import { Severities } from "../../../enums/Severities";


export default /*@__PURE__*/_defineComponent({
  __name: 'FilterForm',
  props: {
    fields: {},
    severity: { default: Severities.Primary }
  },
  emits: ["apply"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { selected, between, form, submit, limit } = useFormFilter(props, emit);

return (_ctx: any,_cache: any) => {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_unref(submit) && _unref(submit)(...args)), ["prevent"])),
    class: "grid"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("label", { for: "Field: *" }, null, -1)),
      _createVNode(SelectInput, {
        modelValue: _unref(form).fields.field.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(form).fields.field.value) = $event)),
        class: _normalizeClass(`w-full ${_unref(form).fields.field.showError ? 'p-invalid' : ''}`),
        placeholder: "Please select",
        optionLabel: "label",
        optionValue: "key",
        limit: 1,
        options: _ctx.fields
      }, null, 8, ["modelValue", "class", "options"])
    ]),
    (_unref(selected))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("label", { for: "Method: *" }, null, -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(selected).methods, (method) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "p-field-radiobutton py-2",
              key: method
            }, [
              _createVNode(_component_RadioButton, {
                class: _normalizeClass(_unref(form).fields.method.showError ? 'p-invalid' : ''),
                modelValue: _unref(form).fields.method.value,
                "onUpdate:modelValue": ($event: any) => ((_unref(form).fields.method.value) = $event),
                name: "method",
                value: method
              }, null, 8, ["class", "modelValue", "onUpdate:modelValue", "value"]),
              _createElementVNode("label", {
                class: _normalizeClass(`mx-1 ${_unref(form).fields.method.showError ? 'text-red-500' : ''}`)
              }, _toDisplayString(_unref(filterMethodsNames)[method]), 3)
            ]))
          }), 128)),
          (_unref(form).fields.method.showError)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(form).fields.method.errors, (error, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "text-sm text-red-500"
                }, _toDisplayString(error), 1))
              }), 128))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(selected))
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass('col-' + (_unref(between) ? '6 pr-1' : '12 pr-3'))
        }, [
          _createElementVNode("label", {
            for: _unref(between) ? 'From:' : 'Value:'
          }, null, 8, _hoisted_3),
          _createVNode(DynamicInput, {
            class: _normalizeClass(`w-full ${_unref(form).fields.term.showError ? 'p-invalid' : ''}`),
            modelValue: _unref(form).fields.term.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(form).fields.term.value) = $event)),
            type: _unref(filterItemTypeToInputType)[_unref(selected).type],
            date: { range: _unref(selected).range },
            datetime: { range: _unref(selected).range },
            placeholder: _unref(selected).placeholder,
            select: { options: _unref(selected).options, optionValue: _unref(selected).keyField, optionLabel: _unref(selected).labelField, limit: _unref(limit) }
          }, null, 8, ["class", "modelValue", "type", "date", "datetime", "placeholder", "select"]),
          (_unref(form).fields.term.showError)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(form).fields.term.errors, (error, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "text-sm text-red-500"
                }, _toDisplayString(error), 1))
              }), 128))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_unref(selected) && _unref(between))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("label", { for: "To: *" }, null, -1)),
          _createVNode(DynamicInput, {
            class: _normalizeClass(`w-full ${_unref(form).fields.max.showError ? 'p-invalid' : ''}`),
            modelValue: _unref(form).fields.max.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(form).fields.max.value) = $event)),
            type: _unref(filterItemTypeToInputType)[_unref(selected).type],
            date: { range: _unref(selected).range },
            datetime: { range: _unref(selected).range },
            placeholder: _unref(selected).placeholder,
            select: { options: _unref(selected).options, optionValue: _unref(selected).keyField, optionLabel: _unref(selected).labelField }
          }, null, 8, ["class", "modelValue", "type", "date", "datetime", "placeholder", "select"]),
          (_unref(form).fields.max.showError)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(form).fields.max.errors, (error, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "text-sm text-red-500"
                }, _toDisplayString(error), 1))
              }), 128))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_unref(selected))
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _withDirectives(_createVNode(_component_Button, {
            class: _normalizeClass(`w-full p-ripple p-button-${_ctx.severity}`),
            type: "submit",
            label: "Add filter",
            icon: "pi pi-plus",
            iconPos: "left"
          }, null, 8, ["class"]), [
            [_directive_ripple]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 32))
}
}

})