export default {
  name: "profile",
  children: [
    // {
    //   path: "/change-password",
    //   name: "change-password",
    //   component: () => import(/* webpackChunkName: "change-password" */ "../views/ChangePassword.vue"),
    // },
  ],
};
